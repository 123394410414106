export const productCategory = [
    {
        id: 1,
        name: 'Kitchenware',
        subCategory: [
            {
                id: 1,
                name: 'Tray'
            },
            {
                id: 2,
                name: 'Food Containers'
            },
            {
                id: 3,
                name: 'Rice Bowl'
            },
            {
                id: 4,
                name: 'Mug'
            },
            {
                id: 13,
                name: 'Wax Melting Cup'
            },
            {
                id: 14,
                name: 'Gastronorm'
            },
            {
                id: 15,
                name: 'Multi-Purpose Royal Basket'
            },
            {
                id: 24,
                name: 'Kitchen Gadgets'
            }
        ]
    },
    {
        id: 2,
        name: 'Buffetware',
        subCategory: [
            {
                id: 5,
                name: 'Beverage Dispenser'
            },
            {
                id: 6,
                name: 'Cereal Dispenser'
            },
            {
                id: 7,
                name: 'Electric Soup Warmer'
            },
            {
                id: 8,
                name: 'Milk /Coffee Dispenser'
            },
            {
                id: 9,
                name: 'Chafing Dish Ⅰ'
            },
            {
                id: 25,
                name: ' Chafing Dish Ⅱ'
            }
        ]
    },
    {
        id: 3,
        name: 'Cutlery Set',
        subCategory: [
            {
                id: 10,
                name: 'General Cutlery Set'
            },
            {
                id: 11,
                name: 'Giftbox Cutlery Set'
            },
            {
                id: 12,
                name: 'Picnic Package'
            }
        ]
    },
    {
        id: 4,
        name: 'Barware',
        subCategory:[
            {
                id: 16,
                name: 'Stainless Steel Ice Bucket'
            },
            {
                id: 17,
                name: 'Stainless Steel Ice Shovel'
            }
        ]
    },
    {
        id: 5,
        name: 'Catering',
        subCategory:[
            {
                id: 18,
                name: 'Barrel'
            },
            {
                id: 19,
                name: 'Straw'
            },
            {
                id: 20,
                name: 'Vacuum Kettle'
            }
        ]
    },
    {
        id: 6,
        name: 'Cookware',
        subCategory:[
            {
                id: 21,
                name: 'Cookware'
            },

        ]
    },
    {
        id: 7,
        name: 'School Pack',
        subCategory:[
            {
                id: 22,
                name: 'Japanese Style Lunch Box'
            },
            {
                id: 23,
                name: 'Stainless Steel Lunch Box'
            }
        ]
    },
]