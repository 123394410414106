<template>
    <div id="app">
        <menu-pc v-if="!_isMobile()"></menu-pc>
        <menu-app v-if="_isMobile()"></menu-app>
        <router-view/>
        <div :id="!_isMobile() ? 'footer' : 'footer-app'" class="clearfix">
            <h2>Contact Us</h2>
            <ul class="sales-product">
                <li><h3>Sales and Product Inquiries</h3></li>
                <li><span>info@lomonhoteless.com</span></li>
                <li><span>marketing@lomonhoteless.com</span></li>
            </ul>
            <ul class="support">
                <li><h3>Contact Lomon Support</h3></li>
                <li><span>customerservice@lomonhoteless.com</span></li>
            </ul>
            <ul class="link clearfix">
                <li>
                    <el-popover
                        placement="top"                        
                        trigger="hover"
                        >
                        <img :src="require('@/assets/whatsapp-code.gif')" />
                        <img slot="reference" :src="require('@/assets/whatsapp.png')" />
                    </el-popover>
                </li>
                <li>
                    <el-popover
                        placement="top"
                        trigger="hover"
                        >
                        <img :src="require('@/assets/wechat-code.gif')" />
                        <img slot="reference" :src="require('@/assets/wechat.png')" />
                    </el-popover>
                </li>
                <li>
                    <el-popover
                        placement="top"                        
                        trigger="hover"
                        >
                        <img :src="require('@/assets/line-code.gif')" />
                        <img slot="reference" :src="require('@/assets/line.png')" />
                    </el-popover>
                </li>                
                <li><a href="https://www.facebook.com/profile.php?id=61561688345089" target="_blank"><img :src="require('@/assets/facebook-original.png')" /></a></li>
                <li><a href="https://www.linkedin.com/company/chaozhou-lomon-hospitality-essentials-co-ltd/about/?viewAsMember=true" target="_blank"><img :src="require('@/assets/linkedin-original.png')" /></a></li>
                <li><a href="https://www.youtube.com/@LomonHospitalityEssentials" target="_blank"><img :src="require('@/assets/youtube.png')" /></a></li>                
            </ul>           
        </div>
    </div>
</template>

<script>
import MenuPC from './components/MenuPC.vue'
import MenuApp from '@/components/MenuApp.vue'
export default {
    data() {
        return {
            
        }
    },
    updated() {
        window.scroll(0, 0);
    },
    mounted() {
        //console.log(this.productCategory[0].name, this.productList, this.productList.filter(i => i.bySubcategory == 10))
        //this.enterMeun(1)
    },
    methods: {
        enterMeun(type) {
            //console.log('enterMeun', type, this.$store.state.curCategory)
            this.$store.state.curCategory = this.productCategory.find(item => item.id == type)             
            if(this.$store.state.curCategory) {
                this.subCategory = this.$store.state.curCategory.subCategory
            }
            this.enterSubMeun(this.subCategory[0].id)            
            this.isActive = true
        },
        leaveProduct() {
            //console.log('leaveProduct')
            this.$store.state.curCategory = {id:0}
            this.isActive = false
        },
        enterSubMeun(subId) {
            this.curSubCategory = this.subCategory.find(item => item.id == subId)
            this.showProduct(this.curSubCategory.id)
            
        },
        showProduct(subCategoryId) {
            //console.log('productList filter', this.productList, this.productList.filter(i => i.bySubcategory == 10), subCategoryId)
            this.productShowList = this.productList.filter(i => i.bySubcategory == subCategoryId)
            //console.log('productShowList', this.productShowList)
        },
        goProudct(id) {
            this.isActive = false
            this.$router.push({ name: 'product', params: { id: id }})
        },        
        // 判断本地是否有该文件
        showimg (url) {
            // 判断结果
            let result = false
            url = './' + url
            // 获取该目录下所有文件名
            const files = require.context('@/assets/product/', false, /\.jpg$/)
            const filedata = files.keys()
            // 判断是否有该文件
            filedata.forEach((item) => {
                if (item === url) {
                    result = true
                }
            })
            // 返回结果
            //console.log('showimg', url, result)
            return result
        },
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        }
    },
    components: {
        'menu-pc': MenuPC,
        'menu-app': MenuApp
    }
}
</script>

<style>
body {
  background-color: #f4e7c5;
}
#app {
  font-family: Montserrat, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
  color: #2c3e50;
}
nav {
    position: relative;
    z-index: 99;
  background-color: #e4e1d3;
  height: 100px;
  border-bottom: 1px solid rgb(216, 215, 215);
  /*box-shadow: 0 4px 10px 0 rgba(0 0 0 / 15%);*/
}
#app nav .ml-first-menu {
    margin-left: 130px;
}
#app nav .bold {
    font-weight: bold;
}

#footer {
    /* font-family: Roboto; */
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
}
#footer h2 {
    float: left;
    padding-left: 60px;
    padding-right: 40px;
    padding-top: 30px;
    font-size: 30px;
}
#footer .sales-product {
    float: left;
    padding-top: 30px;
    font-size: 14px;
}
#footer .sales-product li {    
    line-height: 30px;
}
#footer .support {
    float: left;
    margin-left: 20px;
    padding-top: 30px;
    font-size: 14px;
}
#footer .support li {    
    line-height: 30px;
}
#footer .link {
    float: left;
    margin-left:50px;
    padding-top: 40px;
    font-size: 16px;
}
#footer .link li {
    float: left;
    margin-left: 25px;
    line-height: 30px;
}
#footer .link img {
    width: 35px;
    opacity: 0.6;
}
#footer .link img:hover {
    width: 35px;
    opacity: 1;
}

#footer-app {
    /* font-family: Roboto; */
    width: 100%;
    margin: 0 auto; 
    padding-bottom: 1.5rem;   
}
#footer-app h2 { 
    line-height: 5rem;     
    font-size: 2rem;
    margin-left: 2rem;
}
#footer-app .sales-product {        
    font-size: 1rem;
    line-height: 1.5rem;
    margin-left: 2rem;
}
#footer-app .support {
    margin-top: .5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-left: 2rem;
}
#footer-app .link {
    margin-top: 1rem;
    margin-left: 2rem;
}
#footer-app .link li {
    float: left;
    margin-left: 1rem;
    line-height: 2rem;
}
#footer-app .link li:first-child {
    margin-left: 0rem;
}
#footer-app .link img {
    width: 1.8rem;
    opacity: 0.6;
}
#footer-app .link img:hover {
    width: 1.8rem;
    opacity: 1;
}

.home-product ul .home-product-item .van-image__img {
    border: 2px dashed #0F0;
}
</style>