<template>
    <div>
        <nav>
            <div class="meun">
                <router-link to="/" class="logo-nav">
                    <img :src="require('@/assets/logo.png')" />                
                </router-link>

                <span class="menu-item" :class="{'ml-first-menu': true, 'bold': isActive}" @mouseenter="enterCategory()">Products</span>
                <!--
                <span class="menu-item" v-for="c in productCategory" :key="c.id" :class="{'ml-first-menu': c.id == 1, 'bold': $store.state.curCategory.id == c.id}" @mouseenter="enterMeun(c.id)">{{ c.name }}</span>
                -->
            
                <router-link to="/about" >
                    <span @mouseenter="leaveProduct">About Us</span>
                </router-link>
                <router-link to="/faq" >
                    <span @mouseenter="leaveProduct">FAQ</span>
                </router-link>
                <div class="clearfix"></div>
            </div>
        </nav>
        
        <transition name="nested">
            <div id="product" v-show="isActive" @mouseleave="leaveProduct()">
                <ul class="product-menu">
                    <li v-for="c in productCategory" :key="c.id" :class="{'sub-show': c.id == $store.state.curCategory.id}" @mouseenter="enterMeun(c.id)">{{ c.name }}</li>
                </ul>
                <div class="clearfix" style="border-bottom:2px solid #8c6b6a"></div>
                <ul class="product-menu">
                    <li v-for="s in subCategory" :key="s.id" :class="{'sub-show': s.id == curSubCategory.id}" @mouseenter="enterSubMeun(s.id)">{{ s.name }}</li>
                </ul>
                <div class="clearfix" style="border-bottom:2px solid #8c6b6a"></div>
                <ul class="product-list" v-if="productShowList.length <= 4">
                    <li v-for="p in productShowList" :key="p.id">
                        <div @click="goProudct(p.id)">                            
                            <img :src="showimg(p.img[0]) ? require('@/assets/product/' + p.img[0]) : require('@/assets/product/no-img.jpg')" :alt="p.name" />
                            <h3>{{ p.name }}</h3>
                        </div>
                    </li>
                </ul>
                <ul class="product-list-str" v-else>
                    <li>
                        <div @click="goProudct(p.id)" v-for="p in productShowList.filter((item, index) => {return index <= 6})" :key="p.id">
                            <h3>{{ p.name }}</h3>
                        </div>
                    </li>
                    <li>
                        <div @click="goProudct(p.id)" v-for="p in productShowList.filter((item, index) => {return index > 6 && index <= 12})" :key="p.id">
                            <h3>{{ p.name }}</h3>
                        </div>
                    </li>
                    <!--
                    <li>
                        <router-link to="/" v-for="p in 10" :key="p"><h3>304 Double Layer Stainless Steel Serving Rice Bowl</h3></router-link>
                    </li>
                    -->
                </ul>
                <div class="clearfix"></div>
            </div>
        </transition>
    </div>
</template>

<script>
import { productCategory } from '@/assets/js/product-category'
import { productList } from '@/assets/js/product'
export default {
    name: 'MenuPC',
    data() {
        return {
            isActive: false,
            productCategory: productCategory,
            productList: productList,
            subCategory: [],
            curSubCategory: {},
            productShowList: []
        }
    },
    methods: {
        enterCategory() {
            this.enterMeun(this.productCategory[0].id)
            this.isActive = true
        },
        enterMeun(type) {
            //console.log('enterMeun', type, this.$store.state.curCategory)
            if(type == 0) type = 1
            this.$store.state.curCategory = this.productCategory.find(item => item.id == type)
            if(this.$store.state.curCategory) {
                this.subCategory = this.$store.state.curCategory.subCategory
            }
            this.enterSubMeun(this.subCategory[0].id)
        },
        leaveProduct() {
            //console.log('leaveProduct')
            this.$store.state.curCategory = {id:0}
            this.isActive = false
        },
        enterSubMeun(subId) {
            this.curSubCategory = this.subCategory.find(item => item.id == subId)
            this.showProduct(this.curSubCategory.id)
            
        },
        showProduct(subCategoryId) {
            //console.log('productList filter', this.productList, this.productList.filter(i => i.bySubcategory == 10), subCategoryId)
            this.productShowList = this.productList.filter(i => i.bySubcategory == subCategoryId)
            //console.log('productShowList', this.productShowList)
        },
        goProudct(id) {
            this.isActive = false
            this.$router.push({ name: 'product', params: { id: id }})
        },        
        // 判断本地是否有该文件
        showimg (url) {
            // 判断结果
            let result = false
            url = './' + url
            // 获取该目录下所有文件名
            const files = require.context('@/assets/product/', false, /\.jpg$/)
            const filedata = files.keys()
            // 判断是否有该文件
            filedata.forEach((item) => {
                if (item === url) {
                    result = true
                }
            })
            // 返回结果
            //console.log('showimg', url, result)
            return result
        }
    }
}
</script>

<style>
nav .meun {
  width: 1200px;
  margin: 0 auto;
}
nav .meun .menu-item {
  margin-left: 2rem;
  font-size: 1.2rem;
  line-height: 100px;
  color: #2c3e50;  
  cursor: pointer;
}
nav .meun a {
  margin-left: 2rem;
  font-size: 1.2rem;  
  line-height: 100px;
  color: #2c3e50;
}
nav .logo-nav img {
  float: left;
  height: 50px;
  margin-top:25px;
  margin-left:50px;
}
nav .logo-nav {  
  margin-left: 0.5rem;
  font-size: 2rem;
  font-weight: bold;
}

#product {
    z-index: 2;
    position: absolute;    
    height: 450px;
    color: #303133;

    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
   
    /*
    background-image: url('~@/assets/s1.png');
    background-repeat: repeat-x;
    background-position-y: 0px;
    /*box-shadow:inset 0px 15px 15px -15px #CCC;*/
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 15%);
    box-shadow:inset 0px 15px 15px -15px #CCC;
    background-color: rgb(250, 250, 247);
    width: 100%;    
}

.product-menu {
    width:1200px;
    margin: 0 auto;
    font-size: 16px;    
}
.product-menu li {
    float: left;
    height: 60px;
    line-height: 60px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}
.sub-show {
    font-weight: bold;
    padding-left: 18px !important;
    padding-right: 18px !important;
    border-bottom: 2px solid #2c3e50;
}
.product-list {
    width: 1200px;
    padding-top: 20px;
    margin: 0 auto;
}
.product-list li {
    width: 260px;
    float: left;
    margin-left: 30px;
    cursor: pointer;
}
.product-list img {
    width: 260px;
}
.product-list h3 {
    width: 260px;
    font-size: 12px;
    font-weight: bold;    
    line-height: 25px;
}

.product-list-str {
    width: 1200px;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    margin: 0 auto;
}
.product-list-str li {
    float: left;    
    width: 560px;
}
.product-list-str h3 {  
    font-size: 12px;    
    line-height: 35px;
    background-image: url('~@/assets/point.svg');
    background-repeat: no-repeat;
    background-position: -10px 0px;
    padding-left: 20px;
    cursor: pointer;
}
.active {    
    opacity: 1;
    visibility: 1;
    height: 300px;
}
.no-active {    
    opacity: 0;
    visibility: 0;
    height: 0;
}
/*
nav a.router-link-exact-active {
  color: #42b983;
}
*/
.nested-enter-active, .nested-leave-active {
	transition: all 0.5s ease-in-out;
}
/* delay leave of parent element */
.nested-leave-active {
  transition-delay: 0.5s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(-300px);
  opacity: 0;
}
</style>