<template>
    <div class="menu-app">        
        <nav>            
            <router-link to="/" class="logo-nav">
                <img :src="require('@/assets/logo.png')" />
            </router-link>        
            <!--
            <span class="menu-item" v-for="c in productCategory" :key="c.id" @click="enterMeun(c.id)">{{ c.name }}</span>
            -->
            
            <span class="menu-item" @click="enterMeun(0)">Products</span>
            <router-link to="/about" >
                <span>About Us</span>
            </router-link>
            <router-link to="/faq" >
                <span>FAQ</span>
            </router-link>            
        </nav>
        <van-popup v-model="isActive" position="left" :style="{height: '100%'}">            
            <van-dropdown-menu active-color="#1989fa">
                <van-dropdown-item @change="changeCategory" v-model="categoryValue" :options="categoryOption" title-class="category-title"/>
            </van-dropdown-menu>
            <ul class="category">
                <li v-for="s in subCategory" :key="s.id">
                    <h2>{{ s.name }}</h2>
                    <ul class="product">
                        <li @click="goProudct(p.id)" v-for="p in productList.filter(i => i.bySubcategory == s.id)" :key="p.id">{{ p.name }}</li>
                    </ul>
                </li>
            </ul>
        </van-popup>
    </div>
</template>

<script>
import { Popup, DropdownMenu, DropdownItem } from 'vant';
import { productCategory } from '@/assets/js/product-category'
import { productList } from '@/assets/js/product'
export default {
    name: 'MenuApp',
    data() {
        return {
            active: '',
            isActive: false,
            productCategory: productCategory,
            productList: productList,
            subCategory: [],
            categoryValue: 0,
            categoryOption: [],
        }
    },
    mounted() {        
        this.categoryOption = []
        for(let c of this.productCategory) {
            this.categoryOption.push({
                text: c.name,
                value: c.id
            })
        }        
        //this.enterMeun(1)
    },
    methods: {
        enterMeun(cid) {
            if(cid == 0) cid = 1
            this.$store.state.curCategory = this.productCategory.find(item => item.id == cid)
            if(this.$store.state.curCategory) {
                this.subCategory = this.$store.state.curCategory.subCategory
            }
            this.categoryValue = cid
            this.isActive = true
            console.log('enterMeun', cid, this.isActive)
        },
        changeCategory(val) {
            console.log(val)
            this.subCategory = this.productCategory.find(i => i.id == val).subCategory
        },
        goProudct(id) {
            this.isActive = false
            this.$router.push({ name: 'product', params: { id: id }})
        }
    },
    components: {
        [Popup.name]: Popup,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,        
    },
}
</script>

<style>
.menu-app nav .logo-nav img{
    margin-top: 2rem;
    margin-left: 1rem;
    margin-bottom: .5rem;
}
.menu-app nav {    
    height: 8rem;
}
.menu-app nav a{
    color:#303133;
}
.menu-app nav .menu-item {
    margin-left: 3rem;
}
.menu-app nav span {
    font-size: 1.2rem;
    line-height: 2.5rem;
    margin-left: 1.2rem;
}
.menu-app .category{
    width: 20rem;
    padding-left: 1rem;
}
.menu-app .category-title{
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 4rem;
    padding-left: 1rem;
}
.menu-app .category {
    margin-top: 1rem;
}
.menu-app .category h2{
    font-size: 1rem;
    line-height: 2rem;
    margin-top: .5rem;
}
.menu-app .category .product {    
}
.menu-app .category .product li{
    font-size: .8rem;
    line-height: 1.5rem;    
    background-image: url('~@/assets/point.svg');
    background-repeat: no-repeat;
    background-position: -.7rem -.3rem;
    padding-left: 1rem;
}
</style>