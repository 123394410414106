import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Popover, CollapseTransition } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/common.css'
import './assets/css/font.css'

import 'vant/lib/index.css';

Vue.config.productionTip = false
Vue.use(Popover)
Vue.component(CollapseTransition.name, CollapseTransition)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
