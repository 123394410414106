export const productList =[
//Cutlery Set/General Cutlery Set-10
    {
        bySubcategory:10,//所属产品子类
        id:1,//产品ID
        name:'1010Series(Mainstream Products)',//产品名
        img:['1010Series(MainstreamProducts_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Dinner Knife' },  
                { id: 2, name: 'Dinner Fork' },  
                { id: 3, name: 'Dinner Spoon' },  
                { id: 4, name: 'Dessert Spoon' },  
                { id: 5, name: 'Dessert Fork' },  
                { id: 6, name: 'Tea Spoon' }  
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Material: 410\nLength (cm): 23\nNet Weight (Gram): 66.1\nWidth (cm): 2.0\nThickness (mm): 4.5'  
              },  
              {  
                code: 2,  
                contact: 'Material: 410\nLength (cm): 20.6\nNet Weight (Gram): 33.8\nWidth (cm): 2.4\nThickness (mm): 2.2'  
              },  
              {  
                code: 3,  
                contact: 'Material: 410\nLength (cm): 20.5\nNet Weight (Gram): 48.7\nWidth (cm): 4.5\nThickness (mm): 2.4'  
              },  
              {  
                code: 4,  
                contact: 'Material: 410\nLength (cm): 17\nNet Weight (Gram): 30.8\nWidth (cm): 3.7\nThickness (mm): 2.2'  
              },  
              {  
                code: 5,  
                contact: 'Material: 410\nLength (cm): 17\nNet Weight (Gram): 26.3\nWidth (cm): 2.4\nThickness (mm): 2.2'  
              },  
              {  
                code: 6,  
                contact: 'Material: 410\nLength (cm): 13.8\nNet Weight (Gram): 19.4\nWidth (cm): 3.0\nThickness (mm): 1.8'  
              }  
        ]
    },
    {
        bySubcategory:10,//所属产品子类
        id:2,//产品ID
        name:'1010Series(Cost-effective Products)',//产品名
        img:['1010Series(Cost-effectiveProducts)_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Dinner Knife' },  
                { id: 2, name: 'Dinner Fork' },  
                { id: 3, name: 'Dinner Spoon' },  
                { id: 4, name: 'Tea Spoon' } 
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Material: 410\nLength (cm): 23\nNet Weight (Gram): 50\nWidth (cm): 2.0\nThickness (mm): 3'  
              },  
              {  
                code: 2,  
                contact: 'Material: 410\nLength (cm): 20.5\nNet Weight (Gram): 28.3\nWidth (cm): 2.4\nThickness (mm): 1.5'  
              },  
              {  
                code: 3,  
                contact: 'Material: 410\nLength (cm): 20.2\nNet Weight (Gram): 40.6\nWidth (cm): 4.2\nThickness (mm): 1.5'  
              },  
              {  
                code: 4,  
                contact: 'Material: 410\nLength (cm): 13.7\nNet Weight (Gram): 23.5\nWidth (cm): 3.3\nThickness (mm): 1.5'  
              }   
        ]        
    },
    {
        bySubcategory:10,//所属产品子类
        id:3,//产品ID
        name:'Portugal Series(Mainstream Products)',//产品名
        img:['PortugalSeries(MainstreamProducts)_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Dinner Knife' },  
                { id: 2, name: 'Dinner Fork' },  
                { id: 3, name: 'Dinner Spoon' },  
                { id: 4, name: 'Tea Spoon' }  
            ]
        },
        ],
        describe:[
                {  
                  code: 1,  
                  contact: 'Material: 410\nLength (cm): 22.5\nNet Weight (Gram): 44.2\nWidth (cm): 1.5\nThickness (mm): 5'  
                },  
                {  
                  code: 2,  
                  contact: 'Material: 410\nLength (cm): 21.3\nNet Weight (Gram): 28.6\nWidth (cm): 2.6\nThickness (mm): 2.5'  
                },  
                {  
                  code: 3,  
                  contact: 'Material: 410\nLength (cm): 20.5\nNet Weight (Gram): 35.4\nWidth (cm): 4.7\nThickness (mm): 2.5'  
                },  
                {  
                  code: 4,  
                  contact: 'Material: 410\nLength (cm): 12.9\nNet Weight (Gram): 12.1\nWidth (cm): 2.9\nThickness (mm): 1.9'  
                }   
        ]     
    },
    {
        bySubcategory:10,//所属产品子类
        id:4,//产品ID
        name:'Portugal Series(Cost-effective Products)',//产品名
        img:['PortugalSeries(Cost-effectiveProducts)_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Dinner Knife' },  
                { id: 2, name: 'Dinner Fork' },  
                { id: 3, name: 'Dinner Spoon' },  
                { id: 4, name: 'Tea Spoon' } 
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Material: 410\nLength (cm): 22\nNet Weight (Gram): 36.7\nWidth (cm): 1.4\nThickness (mm): 4'  
              },  
              {  
                code: 2,  
                contact: 'Material: 410\nLength (cm): 21.5\nNet Weight (Gram): 24.7\nWidth (cm): 2.7\nThickness (mm): 1.7'  
              },  
              {  
                code: 3,  
                contact: 'Material: 410\nLength (cm): 20.8\nNet Weight (Gram): 34\nWidth (cm): 4.6\nThickness (mm): 1.5'  
              },  
              {  
                code: 4,  
                contact: 'Material: 410\nLength (cm): 13\nNet Weight (Gram): 13\nWidth (cm): 3.0\nThickness (mm): 1.5'  
              }  
        ]        
    },
//Cutlery Set/Giftbox Cutlery Set-11
    {
        bySubcategory:11,//所属产品子类
        id:5,//产品ID
        name:'24-Piece Wooden Box Gift Set',//产品名
        img:['24-PieceWoodenBoxGiftSet _1.jpg'],//产品图片名，不需要写路径，
        note:'Holds 24 pieces, velvet tray in beige and black',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Package 1' } 
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Product List: Dinner Knife*6,Dinner Spoon*6,Dinner Fork*6,Tea Spoon*6\nQuantity / CTN: 12 Boxes / CTN\nAvailability / MOQ: In stock/Wholesale in full cartons'  
              }
        ]
    },
    {
        bySubcategory:11,//所属产品子类
        id:6,//产品ID
        name:'24-Piece Golden Wooden Box with Handles',//产品名
        img:['24-PieceGoldenWoodenBoxwithHandles_1.jpg'],//产品图片名，不需要写路径，
        note:'Available with and without handles (cheaper) and holds a 24-piece set; Other models with different sizes and colors can fit 16/24/36/42/48 pcs set',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Package 2' } 
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Product List: Dinner Knife*6,Dinner Spoon*6,Dinner Fork*6,Tea Spoon*6\nQuantity / CTN: 12 Boxes / CTN\nAvailability / MOQ: In stock/Wholesale in full cartons'  
              }
        ]
    },    
    {
        bySubcategory:11,//所属产品子类
        id:7,//产品ID
        name:'Toothpaste Box Style Package',//产品名
        img:['ToothpasteBoxStylePackage_1.jpg'],//产品图片名，不需要写路径，
        note:'Holds 16/20/24 pcs set; extra large box holds 30/36/48 pcs set',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Package 3' } 
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Product List: Dinner Knife*6,Dinner Spoon*6,Dinner Fork*6,Tea Spoon*6\nQuantity / CTN: 16 Boxes / CTN\nAvailability / MOQ: In stock/Wholesale in full cartons'  
              }
        ]
    },  
    {
        bySubcategory:11,//所属产品子类
        id:8,//产品ID
        name:'Toothpaste Box Style Package (Black)',//产品名
        img:['ToothpasteBoxStylePackage(Black)_1.jpg'],//产品图片名，不需要写路径，
        note:'Holds 16/20/24 pcs set',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Package 4' } 
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Product List: Dinner Knife*6,Dinner Spoon*6,Dinner Fork*6,Tea Spoon*6\nQuantity / CTN: 16 Boxes / CTN\nAvailability / MOQ: In stock/Wholesale in full cartons'  
              }
        ]
    },  
    {
        bySubcategory:11,//所属产品子类
        id:9,//产品ID
        name:'24-Piece Iron Stand Blue Gift Box Set',//产品名
        img:['24-PieceIronStandBlueGiftBoxSet_1.jpg'],//产品图片名，不需要写路径，
        note:'Hold 24 pieces set, stand color as follows: Silver, Gold, Rose Gold, Colours, and Black',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Package 5' } 
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Product List: Dinner Knife*6,Dinner Spoon*6,Dinner Fork*6,Tea Spoon*6\nQuantity / CTN: 12 Boxes / CTN\nAvailability / MOQ: In stock/Wholesale in full cartons'  
              }
        ]
    }, 
    {
        bySubcategory:11,//所属产品子类
        id:10,//产品ID
        name:'24-piece Flip-top Gift Box (Pearl Cotton)',//产品名
        img:['24-pieceFlip-topGiftBox(PearlCotton)_1.jpg'],//产品图片名，不需要写路径，
        note:'Hold 24 pieces set',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Package 6' } 
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Product List: Dinner Knife*6,Dinner Spoon*6,Dinner Fork*6,Tea Spoon*6\nQuantity / CTN: 15 Boxes / CTN\nAvailability / MOQ: In stock/Wholesale in full cartons'  
              }
        ]
    }, 
    {
        bySubcategory:11,//所属产品子类
        id:11,//产品ID
        name:'24-piece Flip-top Gift Box (Velvet Tray)',//产品名
        img:['24-pieceFlip-topGiftBox(VelvetTray)_1.jpg'],//产品图片名，不需要写路径，
        note:'Hold 24 pieces set',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Package 7' } 
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Product List: Dinner Knife*6,Dinner Spoon*6,Dinner Fork*6,Tea Spoon*6\nQuantity / CTN: 12 Boxes / CTN\nAvailability / MOQ: In stock/Wholesale in full cartons'  
              }
        ]
    }, 
    {
        bySubcategory:11,//所属产品子类
        id:12,//产品ID
        name:'24-piece Set in Black Gift Box with Double-opening Packaging',//产品名
        img:['24-pieceSetinBlackGiftBoxwithDouble-openingPackaging_1.jpg'],//产品图片名，不需要写路径，
        note:'Holds 24 pieces, velvet tray in beige and black',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Package 8' } 
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Product List: Dinner Knife*6,Dinner Spoon*6,Dinner Fork*6,Tea Spoon*6\nQuantity / CTN: 12 Boxes / CTN\nAvailability / MOQ: In stock/Wholesale in full cartons'  
              }
        ]
    }, 
    {
        bySubcategory:11,//所属产品子类
        id:13,//产品ID
        name:'24-Piece Black Gift Box with Transparent Package',//产品名
        img:['24-PieceBlackGiftBoxwithTransparentPackage_1.jpg'],//产品图片名，不需要写路径，
        note:'Holds 24 pieces in a transparent package',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Package 9' } 
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Product List: Dinner Knife*6,Dinner Spoon*6,Dinner Fork*6,Tea Spoon*6\nQuantity / CTN: 12 Boxes / CTN\nAvailability / MOQ: In stock/Wholesale in full cartons'  
              }
        ]
    }, 
    {
        bySubcategory:11,//所属产品子类
        id:14,//产品ID
        name:'24-Piece Red Gift Box with Transparent Package with Bag',//产品名
        img:['24-PieceRedGiftBoxwithTransparentPackagewithBag_1.jpg'],//产品图片名，不需要写路径，
        note:'Holds 24 pieces in a transparent package',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Package 10' } 
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Product List: Dinner Knife*6,Dinner Spoon*6,Dinner Fork*6,Tea Spoon*6\nQuantity / CTN: 12 Boxes / CTN\nAvailability / MOQ: In stock/Wholesale in full cartons'  
              }
        ]
    }, 
    {
        bySubcategory:11,//所属产品子类
        id:15,//产品ID
        name:'Classic Black Gift Box with Logo',//产品名
        img:['ClassicBlackGiftBoxwithLogo_1.jpg'],//产品图片名，不需要写路径，
        note:'Hold 4/5 pcs set; other same package in different size can hold 2/4/5 pcs set',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Package 11' } 
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Product List: Dinner Knife*6,Dinner Spoon*6,Dinner Fork*6,Tea Spoon*6\nQuantity / CTN: 40 Boxes / CTN\nAvailability / MOQ: In stock/Wholesale in full cartons'  
              }
        ]
    }, 
    {
        bySubcategory:11,//所属产品子类
        id:16,//产品ID
        name:'Classic Black Gift Box',//产品名
        img:['ClassicBlackGiftBox_1.jpg'],//产品图片名，不需要写路径，
        note:'Hold 4/5 pcs set; other same package in different size can hold 2/4/5 pcs set',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Package 12' } 
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Product List: Dinner Knife*6,Dinner Spoon*6,Dinner Fork*6,Tea Spoon*6\nQuantity / CTN: 40 Boxes / CTN\nAvailability / MOQ: In stock/Wholesale in full cartons'  
              }
        ]
    }, 
//Cutlery Set/Picnic Package-12
    {
        bySubcategory:12,//所属产品子类
        id:17,//产品ID
        name:'Mood-24-piece Silver-plated Cutlery Set in Egg Case - 1010 Series',//产品名
        img:['Mood-24-pieceSilver-platedCutlerySetinEggCase-1010Series_1.jpg','Mood-24-pieceSilver-platedCutlerySetinEggCase-1010Series_2.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '7.87 x 7.87 x 11.8 inches/ 5 to 10 Pounds' } 
            ]
        },
        {
            id:2,
            name:'Color',
            items:[
                { id: 1, name: 'Sliver', isShowImg:true},
                { id: 2, name: 'Gold', isShowImg:true}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Product List: Dinner Knife*6 , Dinner Spoon*6 , Dinner Fork*6 , Tea Spoon*6\nQuantity / CTN: 8 Pieces / CTN\nMaterial: ABS+410 Stainless Steel/Full Stainless Steel'  
            },
            {  
                code: 2,  
                contact: 'Product List: Dinner Knife*6 , Dinner Spoon*6 , Dinner Fork*6 , Tea Spoon*6\nQuantity / CTN: 8 Pieces / CTN\nMaterial: ABS+410 Stainless Steel/Full Stainless Steel'  
            },
        ]
    }, 
    {
        bySubcategory:12,//所属产品子类
        id:18,//产品ID
        name:'Camping Cutlery Package - 1010 Series',//产品名
        img:['CampingCutleryPackage-1010Series_1.jpg','CampingCutleryPackage-1010Series_2.jpg','CampingCutleryPackage-1010Series_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '9.5 x 9.5 x 1.57 inches / 1.92 Pounds' } 
            ]
        },
        {
            id:2,
            name:'Color',
            items:[
                { id: 1, name: 'Sliver' , isShowImg:true},
                { id: 2, name: 'Gold' , isShowImg:true}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Product List: Dinner Knife*6 , Dinner Spoon*6 , Dinner Fork*6 , Tea Spoon*6\nQuantity / CTN: 20 Pieces / CTN\nMaterial: Black Package+410 Stainless Steel'  
              }
        ]
    }, 
 //Kitchenware/Tray-1
    {
        bySubcategory:1,//所属产品子类
        id:19,//产品ID
        name:'Thick Apple-Style Tray',//产品名
        img:['ThickApple-StylTray_1.jpg'],//产品图片名，不需要写路径，
        note:'Deviation in product weight by plus or minus 5%',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '30cm' },  
                { id: 2, name: '32cm' },  
                { id: 3, name: '34cm' },  
                { id: 4, name: '36cm' },  
                { id: 5, name: '38cm' },  
                { id: 6, name: '40cm' },  
                { id: 7, name: '44cm' },  
                { id: 8, name: '50cm' },  
                { id: 9, name: '55cm' },  
                { id: 10, name: '60cm' }  
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Material: 410\nQuantity / Box: 200\nNetWeight (Gram): 195g\nOuterBoxSpecification(CM): 32*32*45'  
              },  
              {  
                code: 2,  
                contact: 'Material: 410\nQuantity / Box: 200\nNetWeight (Gram): 225g\nOuterBoxSpecification(CM): 34*34*46'  
              },  
              {  
                code: 3,  
                contact: 'Material: 410\nQuantity / Box: 160\nNetWeight (Gram): 245g\nOuterBoxSpecification(CM): 36*35*46'  
              },  
              {  
                code: 4,  
                contact: 'Material: 410\nQuantity / Box: 160\nNetWeight (Gram): 275g\nOuterBoxSpecification(CM): 38*38*45'  
              },  
              {  
                code: 5,  
                contact: 'Material: 410\nQuantity / Box: 120\nNetWeight (Gram): 320g\nOuterBoxSpecification(CM): 40*40*35'  
              },  
              {  
                code: 6,  
                contact: 'Material: 410\nQuantity / Box: 120\nNetWeight (Gram): 360g\nOuterBoxSpecification(CM): 42*42*36'  
              },  
              {  
                code: 7,  
                contact: 'Material: 410\nQuantity / Box: 100\nNetWeight (Gram): 440g\nOuterBoxSpecification(CM): 46*46*30'  
              },  
              {  
                code: 8,  
                contact: 'Material: 410\nQuantity / Box: 80\nNetWeight (Gram): 570g\nOuterBoxSpecification(CM): 52*52*29'  
              },  
              {  
                code: 9,  
                contact: 'Material: 410\nQuantity / Box: 35\nNetWeight (Gram): 750g\nOuterBoxSpecification(CM): 56*13.5*56'  
              },  
              {  
                code: 10,  
                contact: 'Material: 410\nQuantity / Box: 30\nNetWeight (Gram): 890g\nOuterBoxSpecification(CM): 61*11*61'  
              }  
        ]
    }, 
    {
        bySubcategory:1,//所属产品子类
        id:20,//产品ID
        name:'Thin Lemon-Style Tray',//产品名
        img:['ThinLemon-StyleTray_1.jpg'],//产品图片名，不需要写路径，
        note:'Deviation in product weight by plus or minus 5%',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '25cm' },  
                { id: 2, name: '30cm' },  
                { id: 3, name: '32cm' },  
                { id: 4, name: '34cm' },  
                { id: 5, name: '36cm' },  
                { id: 6, name: '38cm' },  
                { id: 7, name: '40cm' },  
                { id: 8, name: '44cm' },  
                { id: 9, name: '50cm' },  
                { id: 10, name: '55cm' },  
                { id: 11, name: '60cm' } 
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Material: 410\n\nQuantity/Box: 300\n\nNetWeight(Gram): 120g\n\nOuterBoxSpecification(CM): 52*27*31'  
            },  
            {  
                code: 2,  
                contact: 'Material: 410\n\nQuantity/Box: 200\n\nNetWeight(Gram): 140g\n\nOuterBoxSpecification(CM): 32*32*42'  
            },  
            {  
                code: 3,  
                contact: 'Material: 410\n\nQuantity/Box: 200\n\nNetWeight(Gram): 165g\n\nOuterBoxSpecification(CM): 34*34*45'  
            },  
            {  
                code: 4,  
                contact: 'Material: 410\n\nQuantity/Box: 160\n\nNetWeight(Gram): 180g\n\nOuterBoxSpecification(CM): 35*35*38'  
            },  
            {  
                code: 5,  
                contact: 'Material: 410\n\nQuantity/Box: 160\n\nNetWeight(Gram): 195g\n\nOuterBoxSpecification(CM): 38*38*38'  
            },  
            {  
                code: 6,  
                contact: 'Material: 410\n\nQuantity/Box: 120\n\nNetWeight(Gram): 210g\n\nOuterBoxSpecification(CM): 40*40*35'  
            },  
            {  
                code: 7,  
                contact: 'Material: 410\n\nQuantity/Box: 120\n\nNetWeight(Gram): 240g\n\nOuterBoxSpecification(CM): 42*42*35'  
            },  
            {  
                code: 8,  
                contact: 'Material: 410\n\nQuantity/Box: 100\n\nNetWeight(Gram): 320g\n\nOuterBoxSpecification(CM): 46*46*27'  
            },  
            {  
                code: 9,  
                contact: 'Material: 410\n\nQuantity/Box: 80\n\nNetWeight(Gram): 450g\n\nOuterBoxSpecification(CM): 52*52*26'  
            },  
            {  
                code: 10,  
                contact: 'Material: 410\n\nQuantity/Box: 35\n\nNetWeight(Gram): 590g\n\nOuterBoxSpecification(CM): 56*13.5*56'  
            },  
            {  
                code: 11,  
                contact: 'Material: 410\n\nQuantity/Box: 30\n\nNetWeight(Gram): 720g\n\nOuterBoxSpecification(CM): 61*11*61'  
            }  
        ]
    }, 
    {
        bySubcategory:1,//所属产品子类
        id:21,//产品ID
        name:'Diamond Tray',//产品名
        img:['DiamondTray_1.jpg'],//产品图片名，不需要写路径，
        note:'Deviation in product weight by plus or minus 5%',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '22cm' },  
                { id: 2, name: '24cm' },  
                { id: 3, name: '26cm' },  
                { id: 4, name: '28cm' },  
                { id: 5, name: '30cm' },  
                { id: 6, name: '32cm' },  
                { id: 7, name: '34cm' },  
                { id: 8, name: '36cm' },  
                { id: 9, name: '38cm' },  
                { id: 10, name: '40cm' },  
                { id: 11, name: '44cm' },  
                { id: 12, name: '50cm' },  
                { id: 13, name: '55cm' },  
                { id: 14, name: '60cm' }  
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Material: 410\nQuantity/Box: 300\nNetWeight(Gram): 100g\nOuterBoxSpecification(CM): 23*31*45'  
              },  
              {  
                code: 2,  
                contact: 'Material: 410\nQuantity/Box: 300\nNetWeight(Gram): 118g\nOuterBoxSpecification(CM): 25*31*49'  
              },  
              {  
                code: 3,  
                contact: 'Material: 410\nQuantity/Box: 200\nNetWeight(Gram): 145g\nOuterBoxSpecification(CM): 27*27*38'  
              },  
              {  
                code: 4,  
                contact: 'Material: 410\nQuantity/Box: 200\nNetWeight(Gram): 160g\nOuterBoxSpecification(CM): 29*29*38'  
              },  
              {  
                code: 5,  
                contact: 'Material: 410\nQuantity/Box: 200\nNetWeight(Gram): 195g\nOuterBoxSpecification(CM): 32*32*45'  
              },  
              {  
                code: 6,  
                contact: 'Material: 410\nQuantity/Box: 200\nNetWeight(Gram): 225g\nOuterBoxSpecification(CM): 34*34*46'  
              },  
              {  
                code: 7,  
                contact: 'Material: 410\nQuantity/Box: 160\nNetWeight(Gram): 245g\nOuterBoxSpecification(CM): 36*35*46'  
              },  
              {  
                code: 8,  
                contact: 'Material: 410\nQuantity/Box: 160\nNetWeight(Gram): 275g\nOuterBoxSpecification(CM): 38*38*45'  
              },  
              {  
                code: 9,  
                contact: 'Material: 410\nQuantity/Box: 120\nNetWeight(Gram): 320g\nOuterBoxSpecification(CM): 40*40*35'  
              },  
              {  
                code: 10,  
                contact: 'Material: 410\nQuantity/Box: 120\nNetWeight(Gram): 360g\nOuterBoxSpecification(CM): 42*42*36'  
              },  
              {  
                code: 11,  
                contact: 'Material: 410\nQuantity/Box: 100\nNetWeight(Gram): 440g\nOuterBoxSpecification(CM): 46*46*30'  
              },  
              {  
                code: 12,  
                contact: 'Material: 410\nQuantity/Box: 80\nNetWeight(Gram): 570g\nOuterBoxSpecification(CM): 52*52*29'  
              },  
              {  
                code: 13,  
                contact: 'Material: 410\nQuantity/Box: 35\nNetWeight(Gram): 750g\nOuterBoxSpecification(CM): 56*13.5*56'  
              },  
              {  
                code: 14,  
                contact: 'Material: 410\nQuantity/Box: 30\nNetWeight(Gram): 890g\nOuterBoxSpecification(CM): 61*11*61'  
              }  
        ]
    },
    {
        bySubcategory:1,//所属产品子类
        id:22,//产品ID
        name:'Full Size Deep Stainless Steel Tray',//产品名
        img:['FullSizeDeepStainlessSteelTray_1.jpg'],//产品图片名，不需要写路径，
        note:'Deviation in product weight by plus or minus 5%',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '60*40*4.8' },  
                { id: 2, name: '50*35*4.8' },  
                { id: 3, name: '45*35*4.8' },  
                { id: 4, name: '40*30*4.8' },  
                { id: 5, name: '36*27*4.8' },  
                { id: 6, name: '32*22*4.8' },  
                { id: 7, name: '27*20*4.8' }
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Material: 410\nQuantity / Box: 50 pieces / Box\nNet Weight (Gram): 950g\n'  
            },  
            {  
                code: 2,  
                contact: 'Material: 410\nQuantity / Box: 60 pieces / Box\nNet Weight (Gram): 630g\n'  
            },  
            {  
                code: 3,  
                contact: 'Material: 410\nQuantity / Box: 70 pieces / Box\nNet Weight (Gram): 580g\n'  
            },  
            {  
                code: 4,  
                contact: 'Material: 410\nQuantity / Box: 100 pieces / Box\nNet Weight (Gram): 410g\n'  
            },  
            {  
                code: 5,  
                contact: 'Material: 410\nQuantity / Box: 120 pieces / Box\nNet Weight (Gram): 350g\n'  
            },  
            {  
                code: 6,  
                contact: 'Material: 410\nQuantity / Box: 180 pieces / Box\nNet Weight (Gram): 290g\n'  
            },  
            {  
                code: 7,  
                contact: 'Material: 410\nQuantity / Box: 180 pieces / Box\nNet Weight (Gram): 210g\n'  
            } 
        ]
    },
    {
        bySubcategory:1,//所属产品子类
        id:23,//产品ID
        name:'Full Size Shallow Stainless Steel Tray',//产品名
        img:['FullSizeShallowStainlessSteelTray_1.jpg'],//产品图片名，不需要写路径，
        note:'Deviation in product weight by plus or minus 5%',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '60*40*2' },  
                { id: 2, name: '50*35*2' },  
                { id: 3, name: '45*35*2' },  
                { id: 4, name: '40*30*2' },  
                { id: 5, name: '36*27*2' },  
                { id: 6, name: '32*22*2' },  
                { id: 7, name: '27*20*2' }  
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Material: 410\nQuantity / Box: 60 pieces / Box\nNet Weight (Gram): 800g\n'  
            },  
            {  
                code: 2,  
                contact: 'Material: 410\nQuantity / Box: 80 pieces / Box\nNet Weight (Gram): 520g\n'  
            },  
            {  
                code: 3,  
                contact: 'Material: 410\nQuantity / Box: 100 pieces / Box\nNet Weight (Gram): 485g\n'  
            },  
            {  
                code: 4,  
                contact: 'Material: 410\nQuantity / Box: 120 pieces / Box\nNet Weight (Gram): 345g\n'  
            },  
            {  
                code: 5,  
                contact: 'Material: 410\nQuantity / Box: 140 pieces / Box\nNet Weight (Gram): 285g\n'  
            },  
            {  
                code: 6,  
                contact: 'Material: 410\nQuantity / Box: 180 pieces / Box\nNet Weight (Gram): 200g\n'  
            },  
            {  
                code: 7,  
                contact: 'Material: 410\nQuantity / Box: 240 pieces / Box\nNet Weight (Gram): 155g\n'  
            }  
        ]
    },
    {
        bySubcategory:1,//所属产品子类
        id:24,//产品ID
        name:'Korean-Style Serving Plate',//产品名
        img:['Korean-StyleServingPlate_1.jpg','Korean-StyleServingPlate_2.jpg'],//产品图片名，不需要写路径，
        note:'Deviation in product weight by plus or minus 5%',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '14cm' },  
                { id: 2, name: '17cm' },  
                { id: 3, name: '20cm' },  
                { id: 4, name: '23cm' },  
                { id: 5, name: '26cm' },  
                { id: 6, name: '30cm' } 
            ]
        },
        {
            id:2,
            name:'Color',
            items:[
                { id:1, name: 'Sliver', isShowImg:true},
                { id:2, name: 'Gold', isShowImg:true}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Quantity / Box: 52g\nNet Weight (Gram): 400\nOuter Box Specification (CM): 30*30*24\n'  
            },  
            {  
                code: 2,  
                contact: 'Quantity / Box: 75g\nNet Weight (Gram): 300\nOuter Box Specification (CM): 35*18*36\n'  
            },  
            {  
                code: 3,  
                contact: 'Quantity / Box: 100g\nNet Weight (Gram): 200\nOuter Box Specification (CM): 41*21*25\n'  
            },  
            {  
                code: 4,  
                contact: 'Quantity / Box: 140g\nNet Weight (Gram): 200\nOuter Box Specification (CM): 24*24*47\n'  
            },  
            {  
                code: 5,  
                contact: 'Quantity / Box: 190g\nNet Weight (Gram): 150\nOuter Box Specification (CM): 27*27*35\n'  
            },  
            {  
                code: 6,  
                contact: 'Quantity / Box: 270g\nNet Weight (Gram): 100\nOuter Box Specification (CM): 31*31*29\n'  
            }   
        ]
    },
    {
        bySubcategory:1,//所属产品子类
        id:25,//产品ID
        name:'General Style in Gold',//产品名
        img:['GeneralStyleinGold_1.jpg'],//产品图片名，不需要写路径，
        note:'Deviation in product weight by plus or minus 5%',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '22cm' },  
                { id: 2, name: '24cm' },  
                { id: 3, name: '26cm' },  
                { id: 4, name: '28cm' },  
                { id: 5, name: '30cm' },  
                { id: 6, name: '32cm' },  
                { id: 7, name: '34cm' },  
                { id: 8, name: '36cm' },  
                { id: 9, name: '38cm' },  
                { id: 10, name: '40cm' },  
                { id: 11, name: '44cm' },  
                { id: 12, name: '50cm' },  
                { id: 13, name: '55cm' },  
                { id: 14, name: '60cm' }
            ]
        },
        ],
        describe:[
                {  
                    code: 1,  
                    contact: "Material:410\nQuantity / Box:300\nNet Weight (Gram):100g\nOuter Box Specification (CM):23*45*32"  
                },  
                {  
                    code: 2,  
                    contact: "Material:410\nQuantity / Box:300\nNet Weight (Gram):118g\nOuter Box Specification (CM):25*49*33"  
                },  
                {  
                    code: 3,  
                    contact: "Material:410\nQuantity / Box:200\nNet Weight (Gram):145g\nOuter Box Specification (CM):27*27*40"  
                },  
                {  
                    code: 4,  
                    contact: "Material:410\nQuantity / Box:200\nNet Weight (Gram):160g\nOuter Box Specification (CM):29*29*40"  
                },  
                {  
                    code: 5,  
                    contact: "Material:410\nQuantity / Box:200\nNet Weight (Gram):195g\nOuter Box Specification (CM):32*32*45"  
                },  
                {  
                    code: 6,  
                    contact: "Material:410\nQuantity / Box:200\nNet Weight (Gram):225g\nOuter Box Specification (CM):34*34*46"  
                },  
                {  
                    code: 7,  
                    contact: "Material:410\nQuantity / Box:160\nNet Weight (Gram):245g\nOuter Box Specification (CM):36*35*46"  
                },  
                {  
                    code: 8,  
                    contact: "Material:410\nQuantity / Box:160\nNet Weight (Gram):275g\nOuter Box Specification (CM):38*38*45"  
                },  
                {  
                    code: 9,  
                    contact: "Material:410\nQuantity / Box:120\nNet Weight (Gram):320g\nOuter Box Specification (CM):40*40*35"  
                },  
                {  
                    code: 10,  
                    contact: "Material:410\nQuantity / Box:120\nNet Weight (Gram):360g\nOuter Box Specification (CM):42*41*36"  
                },  
                {  
                    code: 11,  
                    contact: "Material:410\nQuantity / Box:100\nNet Weight (Gram):440g\nOuter Box Specification (CM):46*46*30"  
                },  
                {  
                    code: 12,  
                    contact: "Material:410\nQuantity / Box:80\nNet Weight (Gram):570g\nOuter Box Specification (CM):52*52*29"  
                },  
                {  
                    code: 13,  
                    contact: "Material:410\nQuantity / Box:35\nNet Weight (Gram):820g\nOuter Box Specification (CM):56*13.5*56"  
                },  
                {  
                    code: 14,  
                    contact: "Material:410\nQuantity / Box:30\nNet Weight (Gram):980g\nOuter Box Specification (CM):61*11*61"  
                } 
        ]
    },
    {
        bySubcategory:1,//所属产品子类
        id:26,//产品ID
        name:'General Style in Silver',//产品名
        img:['GeneralStyleinSilver_1.jpg'],//产品图片名，不需要写路径，
        note:'Deviation in product weight by plus or minus 5%',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '22cm' },  
                { id: 2, name: '24cm' },  
                { id: 3, name: '26cm' },  
                { id: 4, name: '28cm' },  
                { id: 5, name: '30cm' },  
                { id: 6, name: '32cm' },  
                { id: 7, name: '34cm' },  
                { id: 8, name: '36cm' },  
                { id: 9, name: '38cm' },  
                { id: 10, name: '40cm' },  
                { id: 11, name: '44cm' },  
                { id: 12, name: '50cm' },  
                { id: 13, name: '55cm' },  
                { id: 14, name: '60cm' }
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material:410\nQuantity / Box:300\nNet Weight (Gram):100g\nOuter Box Specification (CM):23*45*32"  
            },  
            {  
                code: 2,  
                contact: "Material:410\nQuantity / Box:300\nNet Weight (Gram):118g\nOuter Box Specification (CM):25*49*33"  
            },  
            {  
                code: 3,  
                contact: "Material:410\nQuantity / Box:200\nNet Weight (Gram):145g\nOuter Box Specification (CM):27*27*40"  
            },  
            {  
                code: 4,  
                contact: "Material:410\nQuantity / Box:200\nNet Weight (Gram):160g\nOuter Box Specification (CM):29*29*40"  
            },  
            {  
                code: 5,  
                contact: "Material:410\nQuantity / Box:200\nNet Weight (Gram):195g\nOuter Box Specification (CM):32*32*45"  
            },  
            {  
                code: 6,  
                contact: "Material:410\nQuantity / Box:200\nNet Weight (Gram):225g\nOuter Box Specification (CM):34*34*46"  
            },  
            {  
                code: 7,  
                contact: "Material:410\nQuantity / Box:160\nNet Weight (Gram):245g\nOuter Box Specification (CM):36*35*46"  
            },  
            {  
                code: 8,  
                contact: "Material:410\nQuantity / Box:160\nNet Weight (Gram):275g\nOuter Box Specification (CM):38*38*45"  
            },  
            {  
                code: 9,  
                contact: "Material:410\nQuantity / Box:120\nNet Weight (Gram):320g\nOuter Box Specification (CM):40*40*35"  
            },  
            {  
                code: 10,  
                contact: "Material:410\nQuantity / Box:120\nNet Weight (Gram):360g\nOuter Box Specification (CM):42*41*36"  
            },  
            {  
                code: 11,  
                contact: "Material:410\nQuantity / Box:100\nNet Weight (Gram):440g\nOuter Box Specification (CM):46*46*30"  
            },  
            {  
                code: 12,  
                contact: "Material:410\nQuantity / Box:80\nNet Weight (Gram):570g\nOuter Box Specification (CM):52*52*29"  
            },  
            {  
                code: 13,  
                contact: "Material:410\nQuantity / Box:35\nNet Weight (Gram):750g\nOuter Box Specification (CM):56*13.5*56"  
            },  
            {  
                code: 14,  
                contact: "Material:410\nQuantity / Box:30\nNet Weight (Gram):890g\nOuter Box Specification (CM):61*11*61"  
            } 
        ]
    },
    {
        bySubcategory:1,//所属产品子类
        id:62,//产品ID
        name:'European Style Plate Type A',//产品名
        img:['EuropeanStylePlatetypeA_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '24cm' },  
                { id: 2, name: '26cm' },  
                { id: 3, name: '28cm' },  
                { id: 4, name: '30cm' },  
                { id: 5, name: '33cm' },  
                { id: 6, name: '35cm' },  
                { id: 7, name: '40cm' }  
            ]
        },
        {
            id:2,
            name: 'Color',
            items: [
                { id: 1, name: 'Silver'},
                { id: 2, name: 'Gold'},
                { id: 3, name: 'Rose Gold'},
                { id: 4, name: 'Bronze'}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Material: 410\nQuantity / Box: 200 pieces / Box'  
            },  
            {  
                code: 2,  
                contact: 'Material: 410\nQuantity / Box: 150 pieces / Box'  
            },  
            {  
                code: 3,  
                contact: 'Material: 410\nQuantity / Box: 120 pieces / Box'  
            },  
            {  
                code: 4,  
                contact: 'Material: 410\nQuantity / Box: 100 pieces / Box'  
            },  
            {  
                code: 5,  
                contact: 'Material: 410\nQuantity / Box: 100 pieces / Box'  
            },  
            {  
                code: 6,  
                contact: 'Material: 410\nQuantity / Box: 100 pieces / Box'  
            },  
            {  
                code: 7,  
                contact: 'Material: 410\nQuantity / Box: 100 pieces / Box'  
            }  
        ]
    },
    {
        bySubcategory:1,//所属产品子类
        id:63,//产品ID
        name:'European Style Plate Type B',//产品名
        img:['EuropeanStylePlatetypeB_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '24cm' },  
                { id: 2, name: '26cm' },  
                { id: 3, name: '28cm' },  
                { id: 4, name: '30cm' },  
                { id: 5, name: '33cm' },  
                { id: 6, name: '35cm' },  
                { id: 7, name: '40cm' }  
            ]
        },
        {
            id:2,
            name: 'Color',
            items: [
                { id: 1, name: 'Silver'},
                { id: 2, name: 'Gold'},
                { id: 3, name: 'Rose Gold'},
                { id: 4, name: 'Bronze'},
                { id: 5, name: 'Black'}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Material: 410\nQuantity / Box: 200 pieces / Box'  
            },  
            {  
                code: 2,  
                contact: 'Material: 410\nQuantity / Box: 150 pieces / Box'  
            },  
            {  
                code: 3,  
                contact: 'Material: 410\nQuantity / Box: 120 pieces / Box'  
            },  
            {  
                code: 4,  
                contact: 'Material: 410\nQuantity / Box: 100 pieces / Box'  
            },  
            {  
                code: 5,  
                contact: 'Material: 410\nQuantity / Box: 100 pieces / Box'  
            },  
            {  
                code: 6,  
                contact: 'Material: 410\nQuantity / Box: 100 pieces / Box'  
            },  
            {  
                code: 7,  
                contact: 'Material: 410\nQuantity / Box: 100 pieces / Box'  
            }  
        ]
    },
    {
        bySubcategory:1,//所属产品子类
        id:64,//产品ID
        name:'European Style Plate Type C',//产品名
        img:['EuropeanStylePlatetypeC_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '18cm' },  
                { id: 2, name: '20cm' },  
                { id: 3, name: '22cm' },  
                { id: 4, name: '24cm' },  
                { id: 5, name: '26cm' },  
                { id: 6, name: '28cm' },  
                { id: 7, name: '30cm' },
                { id: 8, name: '35cm' },  
                { id: 9, name: '40cm' }    
            ]
        },
        {
            id:2,
            name: 'Color',
            items: [
                { id: 1, name: 'Silver'},
                { id: 2, name: 'Gold'},
                { id: 3, name: 'Rose Gold'},
                { id: 4, name: 'Bronze'}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Material: 410\nQuantity / Box: 300 pieces / Box\nNet Weight (Gram):115g\nOuter Box Specification (CM):18*12.5*0.8'  
            },  
            {  
                code: 2,  
                contact: 'Material: 410\nQuantity / Box: 300 pieces / Box\nNet Weight (Gram):140g\nOuter Box Specification (CM):20*14.5*0.9'  
            },  
            {  
                code: 3,  
                contact: 'Material: 410\nQuantity / Box: 200 pieces / Box\nNet Weight (Gram):170g\nOuter Box Specification (CM):22*18.5*1.0'  
            },  
            {  
                code: 4,  
                contact: 'Material: 410\nQuantity / Box: 200 pieces / Box\nNet Weight (Gram):230g\nOuter Box Specification (CM):24*17.5*1.1'  
            },  
            {  
                code: 5,  
                contact: 'Material: 410\nQuantity / Box: 120 pieces / Box\nNet Weight (Gram):255g\nOuter Box Specification (CM):26*18.8*1.2'  
            },  
            {  
                code: 6,  
                contact: 'Material: 410\nQuantity / Box: 120 pieces / Box\nNet Weight (Gram):305g\nOuter Box Specification (CM):28*20*1.3'  
            },  
            {  
                code: 7,  
                contact: 'Material: 410\nQuantity / Box: 100 pieces / Box\nNet Weight (Gram):340g\nOuter Box Specification (CM):30*22*1.4'  
            },  
            {  
                code: 8,  
                contact: 'Material: 410\nQuantity / Box: 80 pieces / Box\nNet Weight (Gram):515g\nOuter Box Specification (CM):35*25.5*1.6'  
            },  
            {  
                code: 9,  
                contact: 'Material: 410\nQuantity / Box: 60 pieces / Box\nNet Weight (Gram):645g\nOuter Box Specification (CM):40*29.5*1.8'  
            },  
        ]
    },
    {
        bySubcategory:1,//所属产品子类
        id:65,//产品ID
        name:'Punching Square Tray',//产品名
        img:['PunchingSquareTray_1.jpg','PunchingSquareTray_2.jpg','PunchingSquareTray_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '18 pcs/ Box' },  
                { id: 2, name: '15 pcs/ Box' },  
                { id: 3, name: '12 pcs/ Box' },  
                { id: 4, name: '10 pcs/ Box' },  
                { id: 5, name: '8 pcs/ Box' },  
                { id: 6, name: '6 pcs/ Box' },  
                { id: 7, name: '4 pcs/ Box' },
                { id: 8, name: '3 pcs/ Box' } 
            ]
        },
        {
            id:2,
            name:'Color',
            items:[
                { id: 1, name:'Gold', isShowImg: true},
                { id: 2, name:'Rose Gold', isShowImg: true},
                { id: 3, name:'Silver', isShowImg: true}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Material: 201\nThickness: 0.5mm\nQuantity / Box: 60 pieces / Box\nInner Box Specification (CM): 25*19.1*3.8\nOuter Box Specification (CM):29*23*33'  
            },
            {  
                code: 2,  
                contact: 'Material: 201\nThickness: 0.5mm\nQuantity / Box: 60 pieces / Box\nInner Box Specification (CM): 28*21.9*4.3\nOuter Box Specification (CM):32*26*30'  
            },
            {  
                code: 3,  
                contact: 'Material: 201\nThickness: 0.5mm\nQuantity / Box: 50 pieces / Box\nInner Box Specification (CM): 30.5*23.6*4.6\nOuter Box Specification (CM):35*28*29'  
            },
            {  
                code: 4,  
                contact: 'Material: 201\nThickness: 0.5mm\nQuantity / Box: 50 pieces / Box\nInner Box Specification (CM): 33.5*25.3*5.3\nOuter Box Specification (CM):38*29*29'  
            },
            {  
                code: 5,  
                contact: 'Material: 201\nThickness: 0.5mm\nQuantity / Box: 40 pieces / Box\nInner Box Specification (CM): 39.5*27.5*5.5\nOuter Box Specification (CM):44*32*26'  
            },
            {  
                code: 6,  
                contact: 'Material: 201\nThickness: 0.5mm\nQuantity / Box: 30 pieces / Box\nInner Box Specification (CM): 46*32*6.8\nOuter Box Specification (CM):51*36*23'  
            },
            {  
                code: 7,  
                contact: 'Material: 201\nThickness: 0.5mm\nQuantity / Box: 20 pieces / Box\nInner Box Specification (CM): 46*32*6.8'  
            },
            {  
                code: 8,  
                contact: 'Material: 201\nThickness: 0.5mm\nQuantity / Box: 20 pieces / Box\nInner Box Specification (CM): 56.3*40*8'  
            },
        ]
    },
    {
        bySubcategory:1,//所属产品子类
        id:66,//产品ID
        name:'Rectangular Tray with Handle',//产品名
        img:['RectangularTraywithHandle_1.jpg','RectangularTraywithHandle_2.jpg','RectangularTraywithHandle_3.jpg','RectangularTraywithHandle_4.jpg','RectangularTraywithHandle_5.jpg','RectangularTraywithHandle_6.jpg','RectangularTraywithHandle_7.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '32*22 (CM)' },  
                { id: 2, name: '36*27 (CM)' },  
                { id: 3, name: '40*30 (CM)' },  
                { id: 4, name: '45*35 (CM)' },
                { id: 5, name: '50*35 (CM)' },
                { id: 6, name: '60*40 (CM)' }
            ]
        },
        {
            id:2,
            name:'Material',
            items:[
                { id: 1, name:'201'},
                { id: 2, name:'304'}
            ]
        },
        {
            id:3,
            name:'Height',
            items:[
                { id: 1, name: '2 (CM)' },
                { id: 2, name: '5 (CM)' },
                { id: 3, name: '7 (CM)' },
                { id: 4, name: '10 (CM)'},
                { id: 5, name: '15 (CM)'}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Outside Diameter(CM): 31.5*21.5\nInside Diameter(CM): 28.5*18.5\nBottom Diameter(CM): 27*17'  
            },
            {  
                code: 2,  
                contact: 'Outside Diameter(CM): 35.5*26.5\nInside Diameter(CM): 32.5*23.5\nBottom Diameter(CM): 31*22'    
            },
            {  
                code: 3,  
                contact: 'Outside Diameter(CM): 39.5*29.5\nInside Diameter(CM): 36.5*26.5\nBottom Diameter(CM): 35*25'   
            },
            {  
                code: 4,  
                contact: 'Outside Diameter(CM): 44.5*34.5\nInside Diameter(CM): 41.5*31.5\nBottom Diameter(CM): 40*30'  
            },
            {  
                code: 5,  
                contact: 'Outside Diameter(CM): 49.5*34.5\nInside Diameter(CM): 46.5*31.5\nBottom Diameter(CM): 45*30'   
            },
            {  
                code: 6,  
                contact: 'Outside Diameter(CM): 59.5*39.5\nInside Diameter(CM): 56.5*36.5\nBottom Diameter(CM): 55*35'  
            }
        ]
    },
    {
        bySubcategory:1,//所属产品子类
        id:67,//产品ID
        name:'Deep Golden Food Serving',//产品名
        img:['DeepGoldenFoodServing_1.jpg','DeepGoldenFoodServing_2.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '25*19.1*4.3 (CM)' },  
                { id: 2, name: '28*21.9*5 (CM)' },  
                { id: 3, name: '30.5*23.6*5.3 (CM)' },  
                { id: 4, name: '33.5*25.3*6.1 (CM)' },
                { id: 5, name: '39.5*27.5*6.4 (CM)' },
                { id: 6, name: '46*32*8 (CM)' },
                { id: 7, name: '56*35*9 (CM)' },  
                { id: 8, name: '56.3*40*10 (CM)' }
            ]
        },
        {
            id:2,
            name:'Color',
            items:[
                { id: 1, name:'Silver'},
                { id: 2, name:'Gold'}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Specifications(CM): 25*19.1*4.3'  
            },
            {  
                code: 2,  
                contact: 'Specifications(CM): 28*21.9*5.'  
            },
            {  
                code: 3,  
                contact: 'Specifications(CM): 30.5*23.6*5.3' 
            }, 
            {  
                code: 4,  
                contact: 'Specifications(CM): 25*19.1*4.333.5*25.3*6.1'  
            },
            {  
                code: 5,  
                contact: 'Specifications(CM): 39.5*27.5*6.4' 
            }, 
            {  
                code: 6,  
                contact: 'Specifications(CM): 46*32*8'  
            },
            {  
                code: 7,  
                contact: 'Specifications(CM): 56*35*9' 
            }, 
            {  
                code: 8,  
                contact: 'Specifications(CM): 56.3*40*10'  
            }
        ]
    },
    {
        bySubcategory:1,//所属产品子类
        id:68,//产品ID
        name:'Shallow Golden Food Serving',//产品名
        img:['ShallowGoldenFoodServing_1.jpg','ShallowGoldenFoodServing_2.jpg','ShallowGoldenFoodServing_3.jpg','ShallowGoldenFoodServing_4.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '25*19.1*2 (CM)' },  
                { id: 2, name: '28*21.9*2 (CM)' },  
                { id: 3, name: '30.5*23.6*2 (CM)' },  
                { id: 4, name: '33.5*25.3*2 (CM)' },
                { id: 5, name: '39.5*27.5*2 (CM)' },
                { id: 6, name: '46*32*2 (CM)' },
                { id: 7, name: '56*35*2 (CM)' },  
                { id: 8, name: '56.3*40*2 (CM)' }
            ]
        },
        {
            id:2,
            name:'Color',
            items:[
                { id: 1, name:'Silver'},
                { id: 2, name:'Gold'}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Specifications(CM): 25*19.1*2'  
            },
            {  
                code: 2,  
                contact: 'Specifications(CM): 28*21.9*2.'  
            },
            {  
                code: 3,  
                contact: 'Specifications(CM): 30.5*23.6*2' 
            }, 
            {  
                code: 4,  
                contact: 'Specifications(CM): 25*19.1*4.333.5*25.3*2'  
            },
            {  
                code: 5,  
                contact: 'Specifications(CM): 39.5*27.5*2' 
            }, 
            {  
                code: 6,  
                contact: 'Specifications(CM): 46*32*2'  
            },
            {  
                code: 7,  
                contact: 'Specifications(CM): 56*35*2' 
            }, 
            {  
                code: 8,  
                contact: 'Specifications(CM): 56.3*40*2'  
            }
        ]
    },
//KitchenWare/Food Containers-2

    {
        bySubcategory:2,//所属产品子类
        id:28,//产品ID
        name:'Food Storage Box',//产品名
        img:['FoodStorageBox_1.jpg'],//产品图片名，不需要写路径，
        note:'Popular OEM products',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Shallow-Small' },  
                { id: 2, name: 'Shallow-Medium' },  
                { id: 3, name: 'Shallow-Large' },  
                { id: 4, name: 'Deep-Small' },  
                { id: 5, name: 'Deep-Medium' },  
                { id: 6, name: 'Deep-Large' }  
            ]
        },
        {
            id:2,
            name:'material',
            items:[
                {
                    id: 1, name:'304'
                },
                {
                    id: 2, name:'201'
                }
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Specifications/MM: 161*119*48\nVolume/L: 0.7\nPCS/1 Box: 84"  
            } ,
            {  
                code: 2,  
                contact: "Specifications/MM: 196*149*53\nVolume/L: 1.2\nPCS/1 Box: 56"  
            } ,
            {  
                code: 3,  
                contact: "Specifications/MM: 237*182*58\nVolume/L: 1.9\nPCS/1 Box: 42"  
            } ,
            {  
                code: 4,  
                contact: "Specifications/MM: 161*119*72\nVolume/L: 1\nPCS/1 Box: 72"  
            } ,
            {  
                code: 5,  
                contact: "Specifications/MM: 196*149*83\nVolume/L: 1.8\nPCS/1 Box: 48"  
            } ,
            {  
                code: 6,  
                contact: "Specifications/MM: 237*182*93\nVolume/L: 3\nPCS/1 Box: 36"  
            } 
        ]
    },
    {
        bySubcategory:2,//所属产品子类
        id:29,//产品ID
        name:'Food Containers',//产品名
        img:['FoodContainers_1.jpg','FoodContainers_2.jpg','FoodContainers_3.jpg','FoodContainers_4.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '12cm(400ML)' },  
                { id: 2, name: '14cm(800ML)' },  
                { id: 3, name: '16cm(1200ML)' },  
                { id: 4, name: '18cm(1800ML)' },  
                { id: 5, name: '20cm(2400ML)' },  
                { id: 6, name: '4-Piece Set (12/14/16/18)' },  
                { id: 7, name: '4-Piece Set (14/16/18/20)' },  
                { id: 8, name: '5-Piece Set' }  
            ]
        },
        {
            id:2,
            name:'material',
            items:[
                {
                    id: 1, name:'304'
                }
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Net Weight / Gram: 102g\nPieces(Set) / Box: 120\nOuter Box Specification (CM): 64*41*51.5\nVolume / ML: 450"  
            } ,
            {  
                code: 2,  
                contact: "Net Weight / Gram: 142g\nPieces(Set) / Box: 100\nOuter Box Specification (CM): 74*40.5*59\nVolume / ML:750"  
            } ,
            {  
                code: 3,  
                contact: "Net Weight / Gram: 182g\nPieces(Set) / Box: 80\nOuter Box Specification (CM): 68*47*67.5\nVolume / ML:1200"  
            } ,
            {  
                code: 4,  
                contact: "Net Weight / Gram: 254g\nPieces(Set) / Box: 60\nOuter Box Specification (CM): 77*54.5*58\nVolume / ML:1800"  
            } ,
            {  
                code: 5,  
                contact: "Net Weight / Gram: 306g\nPieces(Set) / Box: 20\nOuter Box Specification (CM): 60.5*43*43\nVolume / ML:2400"  
            } ,
            {  
                code: 6,  
                contact: "Net Weight / Gram: 720g\nPieces(Set) / Box: 30\nOuter Box Specification (CM): 60.5*43*43"  
            } ,
            {  
                code: 7,  
                contact: "Net Weight / Gram: 910g\nPieces(Set) / Box: 20\nOuter Box Specification (CM): 60.5*43*43"  
            } ,
            {  
                code: 8,  
                contact: "Net Weight / Gram: 1050g\nPieces(Set) / Box: 20\nOuter Box Specification (CM): 60.5*43*43"  
            } 
        ]
    },
//KitchenWare/Rice Bowl-3
    {
        bySubcategory:3,//所属产品子类
        id:30,//产品ID
        name:'Stainless Steel Serving Rice Bowl (Bright)(304 Exterior and Interior)',//产品名
        img:['StainlessSteelServingRiceBowl(Bright)(304ExteriorandInterior)_1.jpg','StainlessSteelServingRiceBowl(Bright)(304ExteriorandInterior)_2.jpg'],//产品图片名，不需要写路径，
        note:'It is safe to consume the inner and outer layers of this product, which are both constructed of 304 stainless steel and vacuum-plated for food-grade, eco-friendly materials.',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '10cm' },  
                { id: 2, name: '11cm' },  
                { id: 3, name: '12cm' },  
                { id: 4, name: '15cm' }, 
            ]
        },
        {
            id:2,
            name:'Color',
            items:[
                {
                    id: 1, name:'Gold' , isShowImg:true
                },
                {
                    id: 2, name: 'Silver' , isShowImg:true
                }
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Net Weight / Gram: 110g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 45*37*35"  
            } ,
            {  
                code: 2,  
                contact: "Net Weight / Gram: 130g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 49*39*39"  
            } ,
            {  
                code: 3,  
                contact: "Net Weight / Gram: 150g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 53*43*42"  
            } ,
            {  
                code: 4,  
                contact: "Net Weight / Gram: 250g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 65*44*52"  
            } ,
        ]
    },
    {
        bySubcategory:3,//所属产品子类
        id:126,//产品ID
        name:'Stainless Steel Serving Rice Bowl (Matte)(304 Exterior and Interior)',//产品名
        img:['StainlessSteelServingRiceBowl(Matte)(304ExteriorandInterior)_1.jpg','StainlessSteelServingRiceBowl(Matte)(304ExteriorandInterior)_2.jpg'],//产品图片名，不需要写路径，
        note:'It is safe to consume the inner and outer layers of this product, which are both constructed of 304 stainless steel and vacuum-plated for food-grade, eco-friendly materials.',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '10cm' },  
                { id: 2, name: '11cm' },  
                { id: 3, name: '12cm' },  
                { id: 4, name: '15cm' },  
            ]
        },
        {
            id:2,
            name:'Color',
            items:[
                {
                    id: 1, name:'Silver' , isShowImg:true
                },
                {
                    id: 2, name: 'Gold' , isShowImg:true
                }
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Net Weight / Gram: 110g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 45*37*35"  
            } ,
            {  
                code: 2,  
                contact: "Net Weight / Gram: 130g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 49*39*39"  
            } ,
            {  
                code: 3,  
                contact: "Net Weight / Gram: 150g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 53*43*42"  
            } ,
            {  
                code: 4,  
                contact: "Net Weight / Gram: 250g\nQuantity / Gift Box: 6 pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /96 Bowls /1 \nOuter Box Specification (CM): 65*44*52"  
            } ,
        ]
    },
    {
        bySubcategory:3,//所属产品子类
        id:31,//产品ID
        name:'Stainless Steel Serving Rice Bowl (Bright)(201 Exterior and 304 Interior)',//产品名
        img:['StainlessSteelServingRiceBowl(Bright)(201Exteriorand304Interior)_1.jpg','StainlessSteelServingRiceBowl(Bright)(201Exteriorand304Interior)_2.jpg'],//产品图片名，不需要写路径，
        note:'Note: It is safe to consume the product outer 201, inner made by 304 stainless steel material and vacuum-plated for food-grade, eco-friendly materials.',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '11.5cm' },  
                { id: 2, name: '12.5cm' },  
                { id: 3, name: '15cm' },  
            ]
        },
        {
            id:2,
            name:'Color',
            items:[
                {
                    id: 1, name:'Gold', isShowImg:true
                },
                {
                    id: 2, name: 'Silver', isShowImg:true
                }
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Net Weight / Gram: 130g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 48*45*39"  
            } ,
            {  
                code: 2,  
                contact: "Net Weight / Gram: 130g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 53*46*42"  
            } ,
            {  
                code: 3,  
                contact: "Net Weight / Gram: 150g\nQuantity / Gift Box: 6 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 65*44*52"  
            } 
        ]
    },
    {
        bySubcategory:3,//所属产品子类
        id:127,//产品ID
        name:'Stainless Steel Serving Rice Bowl (Matte)(201 Exterior and 304 Interior)',//产品名
        img:['StainlessSteelServingRiceBowl(Matte)(201Exteriorand304Interior)_1.jpg','StainlessSteelServingRiceBowl(Matte)(201Exteriorand304Interior)_2.jpg'],//产品图片名，不需要写路径，
        note:'Note: It is safe to consume the product outer 201, inner made by 304 stainless steel material and vacuum-plated for food-grade, eco-friendly materials.',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '11.5cm' },  
                { id: 2, name: '2.5cm' },  
                { id: 3, name: '15cm' },
            ]
        },
        {
            id:2,
            name:'Color',
            items:[
                {
                    id: 1, name:'Silver', isShowImg:true
                },
                {
                    id: 2, name: 'Gold', isShowImg:true
                }
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Net Weight / Gram: 130g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 48*45*39"  
            } ,
            {  
                code: 2,  
                contact: "Net Weight / Gram: 130g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 53*46*42"  
            } ,
            {  
                code: 3,  
                contact: "Net Weight / Gram: 150g\nQuantity / Gift Box: 6 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 65*44*52"  
            } 
        ]
    },
    {
        bySubcategory:3,//所属产品子类
        id:128,//产品ID
        name:'Stainless Steel Hammered Serving Rice Bowl (Bright)(201 Exterior and 304 Interior)',//产品名
        img:['StainlessSteelHammeredServingRiceBowl(Bright)(201Exteriorand304Interior)_1.jpg','StainlessSteelHammeredServingRiceBowl(Bright)(201Exteriorand304Interior)_2.jpg'],//产品图片名，不需要写路径，
        note:'Note: It is safe to consume the product outer 201, inner made by 304 stainless steel material and vacuum-plated for food-grade, eco-friendly materials.',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '11.5cm' },  
                { id: 2, name: '12.5cm' },  
                { id: 3, name: '15cm' },  
            ]
        },
        {
            id:2,
            name:'Color',
            items:[
                {
                    id: 1, name:'Gold', isShowImg:true
                },
                {
                    id: 2, name: 'Silver', isShowImg:true
                }
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Net Weight / Gram: 130g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 48*45*39"  
            } ,
            {  
                code: 2,  
                contact: "Net Weight / Gram: 130g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 53*46*42"  
            } ,
            {  
                code: 3,  
                contact: "Net Weight / Gram: 150g\nQuantity / Gift Box: 6 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 65*44*52"  
            } 
        ]
    },
    {
        bySubcategory:3,//所属产品子类
        id:129,//产品ID
        name:'Stainless Steel Hammered Serving Rice Bowl (Matte)(201 Exterior and 304 Interior)',//产品名
        img:['StainlessSteelHammeredServingRiceBowl(Matte)(201Exteriorand304Interior)_1.jpg','StainlessSteelHammeredServingRiceBowl(Matte)(201Exteriorand304Interior)_2.jpg'],//产品图片名，不需要写路径，
        note:'Note: It is safe to consume the product outer 201, inner made by 304 stainless steel material and vacuum-plated for food-grade, eco-friendly materials.',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '11.5cm' },  
                { id: 2, name: '12.5cm' },  
                { id: 3, name: '15cm' },  
            ]
        },
        {
            id:2,
            name:'Color',
            items:[
                {
                    id: 1, name:'Gold', isShowImg:true
                },
                {
                    id: 2, name: 'Silver', isShowImg:true
                }
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Net Weight / Gram: 130g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 48*45*39"  
            } ,
            {  
                code: 2,  
                contact: "Net Weight / Gram: 130g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 53*46*42"  
            } ,
            {  
                code: 3,  
                contact: "Net Weight / Gram: 150g\nQuantity / Gift Box: 6 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 65*44*52"  
            } 
        ]
    },
    {
        bySubcategory:3,//所属产品子类
        id:32,//产品ID
        name:'304 Double Layer Stainless Steel Serving Rice Bowl (Bright) (304 Exterior and Interior)',//产品名
        img:['304DoubleLayerStainlessSteelServingRiceBowl(Bright)(304ExteriorandInterior)_1.jpg'],//产品图片名，不需要写路径，
        note:'Note: It is safe to consume the product outer 201, inner made by 304 stainless steel material and vacuum-plated for food-grade, eco-friendly materials.',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Bright-10.5cm' },  
                { id: 2, name: 'Bright-11.5cm' },  
                { id: 3, name: 'Bright-12.5cm' } 
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Net Weight / Gram: 110g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 44*43*36"  
            } ,
            {  
                code: 2,  
                contact: "Net Weight / Gram: 130g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 48*45*39"  
            } ,
            {  
                code: 3,  
                contact: "Net Weight / Gram: 150g\nQuantity / Gift Box: 10 Pieces /1 Box\nNumber of Boxes/Pcs/1 Package: 12 Boxes /120 Bowls /1 \nOuter Box Specification (CM): 53*46*42"  
            } ,
        ]
    },
//KitchenWare/Mug-4
    {
        bySubcategory:4,//所属产品子类
        id:33,//产品ID
        name:'Stainless Steel Mug with Lid',//产品名
        img:['StainlessSteelMugwithLid_1.jpg','StainlessSteelMugwithLid_2.jpg','StainlessSteelMugwithLid_3.jpg'],//产品图片名，不需要写路径，
        note:'6 Pieces / Gift Box',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '200ML' },  
                { id: 2, name: '280ML' },  
                { id: 3, name: '400ML' },
                { id: 4, name: '500ML' },
            ]
        },
        {
            id: 2,
            name: 'Color',
            items:[
                { id: 1,name: 'Sliver' , isShowImg:true},
                { id: 2,name: 'Gold' , isShowImg:true}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Pcs / Box: 180\nOuter Box Specification (CM): 46*45.5*46"  
            } ,
            {  
                code: 2,  
                contact: "Pcs / Box: 150\nOuter Box Specification (CM): 49*41*50"  
            } ,
            {  
                code: 3,  
                contact: "Pcs / Box: 120\nOuter Box Specification (CM): 52.5*43*47.5"  
            } ,
            {  
                code: 4,  
                contact: "Pcs / Box: 100"  
            } ,
        ]
    },
/*    
    {
        bySubcategory:4,//所属产品子类
        id:34,//产品ID
        name:'Stainless Steel Mug with Lid(Gift Box)',//产品名
        img:['StainlessSteelMugwithLid(gift box)_1.jpg'],//产品图片名，不需要写路径，
        note:'6 Pieces / Gift BoIndividually-wrapped',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '200ML' },  
                    { id: 2, name: '280ML' },  
                    { id: 3, name: '400ML' },
                    { id: 4, name: '500ML' },
                ]
            },
            {
                id: 2,
                name: 'color',
                items:[
                    { id: 1,name: 'Gold'},
                    { id: 2,name: 'Sliver'}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Pcs / Box: 120\nOuter Box Specification (CM): 49*41*54.5"  
            } ,
            {  
                code: 2,  
                contact: "Pcs / Box: 100\nOuter Box Specification (CM): 55*43*49"  
            } ,
            {  
                code: 3,  
                contact: "Pcs / Box: 80\nOuter Box Specification (CM): 46.5*45*54.5"  
            } ,
            {  
                code: 4,  
                contact: "Pcs / Box: 60\nOuter Box Specification (CM): 50*49.5*48"  
            } 
        ]
    },
*/
//Wax Melting Cup-13
    {
        bySubcategory:13,//所属产品子类
        id:69,//产品ID
        name:'Wax Melting Cup',//产品名
        img:['WaxMeltingCup_1.jpg','WaxMeltingCup_2.jpg','WaxMeltingCup_3.jpg','WaxMeltingCup_4.jpg','WaxMeltingCup_5.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '0.7L' },  
                { id: 2, name: '1.2L' },  
                { id: 3, name: '1.8L' },
                { id: 4, name: '3L' },
            ]
        },
        {
            id: 2,
            name: 'Color',
            items:[
                { id: 1,name: 'Silver with Black Handle'},
                { id: 2,name: 'Sliver with Wood Handle'},
                { id: 3,name: 'Black with Black Handle'},
                { id: 4,name: 'Black with Wood Handle'},
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Diameter(MM): 98\nHeight (MM): 115"  
            } ,
            {  
                code: 2,  
                contact: "Diameter(MM): 120\nHeight (MM): 125"  
            } ,
            {  
                code: 3,  
                contact: "Diameter(MM): 136\nHeight (MM): 140"  
            } ,
            {  
                code: 4,  
                contact: "Diameter(MM): 136\nHeight (MM): 205"  
            } ,
        ]
    },

//Gastronorm-14
    {
        bySubcategory:14,//所属产品子类
        id:70,//产品ID
        name:'Gastronorm-Gold',//产品名
        img:['Gastronorm-Gold_1.jpg','Gastronorm-Gold_2.jpg','Gastronorm-Gold_3.jpg','Gastronorm-Gold_4.jpg','Gastronorm-Gold_5.jpg','Gastronorm-Gold_6.jpg','Gastronorm-Gold_7.jpg','Gastronorm-Gold_8.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '32*22 (CM)' },  
                { id: 2, name: '36*27 (CM)' },  
                { id: 3, name: '40*30 (CM)' },  
                { id: 4, name: '45*35 (CM)' },
                { id: 5, name: '50*35 (CM)' },
                { id: 6, name: '60*40 (CM)' },
                { id: 7, name: '1/1'},
                { id: 8, name: '1/2'},
                { id: 9, name: '1/3'},
                { id: 10, name: '1/4'},
                { id: 11, name: '1/6'},
                { id: 12, name: '1/9'},


            ]
        },
        {
            id:2,
            name:'Height',
            items:[
                { id: 1, name: '7 (CM)' },
                { id: 2, name: '10 (CM)' },
                { id: 3, name: '15 (CM)' },
                { id: 4, name: '20 (CM)'}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Outside Diameter(CM): 31.5*21.5\nInside Diameter(CM): 28.7*18.8\nBottom Diameter(CM): 26.5*17'  
            },
            {  
                code: 2,  
                contact: 'Outside Diameter(CM): 35.5*26.5\nInside Diameter(CM): 32.6*23.6\nBottom Diameter(CM): 31*22'    
            },
            {  
                code: 3,  
                contact: 'Outside Diameter(CM): 39.5*29.5\nInside Diameter(CM): 36.6*26.6\nBottom Diameter(CM): 34.5*24.5'   
            },
            {  
                code: 4,  
                contact: 'Outside Diameter(CM): 44.5*34.5\nInside Diameter(CM): 41.5*31.5\nBottom Diameter(CM): 40*30'  
            },
            {  
                code: 5,  
                contact: 'Outside Diameter(CM): 49.5*34.5\nInside Diameter(CM): 46.6*31.6\nBottom Diameter(CM): 45*30'   
            },
            {  
                code: 6,  
                contact: 'Outside Diameter(CM): 59.5*39.5\nInside Diameter(CM): 56.5*36.5\nBottom Diameter(CM): 55*35'  
            },
            
            {  
                code: 7,  
                contact: ''  
            },
            {  
                code: 8,  
                contact: ''  
            },
            {  
                code: 9,  
                contact: ''  
            },
            {  
                code: 10,  
                contact: ''  
            },
            {  
                code: 11,  
                contact: ''  
            },
            {  
                code: 12,  
                contact: ''  
            },
        ]
    },
    {
        bySubcategory:14,//所属产品子类
        id:71,//产品ID
        name:'Gastronorm-Clear Polycarbonate  ',//产品名
        img:['Gastronorm-ClearPolycarbonate_1.jpg','Gastronorm-ClearPolycarbonate_2.jpg','Gastronorm-ClearPolycarbonate_3.jpg','Gastronorm-ClearPolycarbonate_4.jpg','Gastronorm-ClearPolycarbonate_5.jpg','Gastronorm-ClearPolycarbonate_6.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '1/1' },  
                { id: 2, name: '1/2' },  
                { id: 3, name: '1/3' },  
                { id: 4, name: '1/4' },
                { id: 5, name: '1/6' },
                { id: 6, name: '1/9' }
            ]
        },
        {
            id:2,
            name:'Material',
            items:[
                { id: 1, name: 'PC(Polycarbonate)' }
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Dimension(MM): 530*325\nHeight(MM): 65/100/150/200\nPCS/CTN: 24'  
            },
            {  
                code: 2,  
                contact: 'Dimension(MM): 325*265\nHeight(MM): 65/100/150/200\nPCS/CTN: 40/36/24/24/24'      
            },
            {  
                code: 3,  
                contact: 'Dimension(MM): 325*175\nHeight(MM): 65/100/150/200\nPCS/CTN: 36/36/24/24/24'    
            },
            {  
                code: 4,  
                contact: 'Dimension(MM): 265*160\nHeight(MM): 65/100/150\nPCS/CTN: 48/36/32'    
            },
            {  
                code: 5,  
                contact: 'Dimension(MM): 175*160\nHeight(MM): 65/100/150\nPCS/CTN: 72/48/48'    
            },
            {  
                code: 6,  
                contact: 'Dimension(MM): 175*110\nHeight(MM): 65/100\nPCS/CTN: 72'    
            }
        ]
        
    },
    {
        bySubcategory:14,//所属产品子类
        id:72,//产品ID
        name:'Gastronorm-Stainless Steel',//产品名
        img:['Gastronorm-StainlessSteel_1.jpg','Gastronorm-StainlessSteel_2.jpg','Gastronorm-StainlessSteel_3.jpg','Gastronorm-StainlessSteel_4.jpg','Gastronorm-StainlessSteel_5.jpg','Gastronorm-StainlessSteel_6.jpg','Gastronorm-StainlessSteel_7.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '1/1' },  
                { id: 2, name: '1/2' },  
                { id: 3, name: '1/3' },  
                { id: 4, name: '1/4' },
                { id: 5, name: '1/6' },
                { id: 6, name: '1/9' }
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Dimension(MM): 530*325\nHeight(MM): 65/100/150/200'  
            },
            {  
                code: 2,  
                contact: 'Dimension(MM): 325*265\nHeight(MM): 65/100/150/200'      
            },
            {  
                code: 3,  
                contact: 'Dimension(MM): 325*176\nHeight(MM): 65/100/150/200'    
            },
            {  
                code: 4,  
                contact: 'Dimension(MM): 265*162\nHeight(MM): 65/100/150/200'         
            },
            {  
                code: 5,  
                contact: 'Dimension(MM): 176*162\nHeight(MM): 65/100/150/200'         
            },
            {  
                code: 6,  
                contact: 'Dimension(MM): 176*108\nHeight(MM): 65/100/150/200'         
            }
        ]
    },
//Kitchen Gadgets-24
    {
        bySubcategory:24,//所属产品子类
        id:73,//产品ID
        name:'Frame - For Polycarbonate GN',//产品名
        img:['Frame-ForPolycarbonateGN_1.jpg','Frame-ForPolycarbonateGN_2.jpg','Frame-ForPolycarbonateGN_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Type A' , isShowImg:true},  
                { id: 2, name: 'Type B' , isShowImg:true},  
                { id: 3, name: 'Type C' , isShowImg:true}
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Long: 36-60cm\nWide: 18cm\nHeight: 16cm/21cm'  
            },
            {  
                code: 2,  
                contact: 'Long: 58-105cm\nWide: 18cm\nHeight: 16cm/21cm'      
            },
            {  
                code: 3,  
                contact: 'For more precise information, contact us at info@lomonhoteless.com'    
            }
        ]
    },
    {
        bySubcategory:24,//所属产品子类
        id:74,//产品ID
        name:'Frame - For Stainless Steel GN',//产品名
        img:['Frame-ForStainlessSteelGN_1.jpg','Frame-ForStainlessSteelGN_2.jpg','Frame-ForStainlessSteelGN_3.jpg','Frame-ForStainlessSteelGN_4.jpg','Frame-ForStainlessSteelGN_5.jpg','Frame-ForStainlessSteelGN_6.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Type A' , isShowImg:true},  
                { id: 2, name: 'Type B' , isShowImg:true},  
                { id: 3, name: 'Type C' , isShowImg:true},
                { id: 4, name: 'Type D' , isShowImg:true},  
                { id: 5, name: 'Type E' , isShowImg:true},  
                { id: 6, name: 'Type F' , isShowImg:true}
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'For more precise information, contact us at info@lomonhoteless.com'  
            },
            {  
                code: 2,  
                contact: 'For more precise information, contact us at info@lomonhoteless.com'      
            },
            {  
                code: 3,  
                contact: 'For more precise information, contact us at info@lomonhoteless.com'    
            },
            {  
                code: 4,  
                contact: 'For more precise information, contact us at info@lomonhoteless.com'  
            },
            {  
                code: 5,  
                contact: 'For more precise information, contact us at info@lomonhoteless.com'      
            },
            {  
                code: 6,  
                contact: 'For more precise information, contact us at info@lomonhoteless.com'    
            },
        ]
    },
    {
        bySubcategory:24,//所属产品子类
        id:93,//产品ID
        name:'Adjustable Mandoline Food Slicer for Kitchen',//产品名
        img:['AdjustableMandolineFoodSlicerforKitchen_1.jpg','AdjustableMandolineFoodSlicerforKitchen_2.jpg','AdjustableMandolineFoodSlicerforKitchen_3.jpg','AdjustableMandolineFoodSlicerforKitchen_4.jpg','AdjustableMandolineFoodSlicerforKitchen_5.jpg','AdjustableMandolineFoodSlicerforKitchen_6.jpg',],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Specifications / Capacity 13.5cm x 36cm/ 670ML' }
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'For more precise information, contact us at info@lomonhoteless.com'  
            }
        ]
    },
    {
        bySubcategory:24,//所属产品子类
        id:94,//产品ID
        name:'4 in 1 Colander with Mixing Bowl with Lids Set',//产品名
        img:['4in1ColanderwithMixingBowlwithLidsSet_1.jpg','4in1ColanderwithMixingBowlwithLidsSet_2.jpg','4in1ColanderwithMixingBowlwithLidsSet_3.jpg','4in1ColanderwithMixingBowlwithLidsSet_4.jpg','4in1ColanderwithMixingBowlwithLidsSet_5.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Exterior: 31cm x 14cm/ Interior: 23cm x 9.3cm' }
            ]
        },
        {
            id:2,
            name:'Color',
            items:[
                { id:1, name:'Pink', isShowImg:true},
                { id:2, name:'Beige', isShowImg:true},
                { id:3, name:'Blue', isShowImg:true},
                { id:4, name:'Green', isShowImg:true}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Weight: 2.09 Pounds\nMaterial: Polypropylene, Stainless Steel'  
            }
        ]
    },
    {
        bySubcategory:24,//所属产品子类
        id:95,//产品ID
        name:'Multi-Functional Home Cactus Sink Strainer',//产品名
        img:['Multi-FunctionalHomeCactusSinkStrainer_1.jpg','Multi-FunctionalHomeCactusSinkStrainer_2.jpg','Multi-FunctionalHomeCactusSinkStrainer_3.jpg','Multi-FunctionalHomeCactusSinkStrainer_4.jpg','Multi-FunctionalHomeCactusSinkStrainer_5.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '7.5cm x 5cm x 8.5cm' },
                { id: 2, name: '10.8cm x 5cm x 8.5cm'}
            ]
        },
        {
            id:2,
            name:'Color',
            items:[
                { id: 1, name:'Yellow' , isShowImg:true},
                { id: 2, name:'Green' , isShowImg:true}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'For more precise information, contact us at info@lomonhoteless.com'  
            }
        ]
    },

//Multi-Purpose Royal Basket-15
    {
        bySubcategory:15,//所属产品子类
        id:75,//产品ID
        name:'Gold Multi-Purpose Royal Basket',//产品名
        img:['GoldMulti-PurposeRoyalBasket_1.jpg','GoldMulti-PurposeRoyalBasket_2.jpg','GoldMulti-PurposeRoyalBasket_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '13.5 cm' },  
                { id: 2, name: '16.5 cm' },  
                { id: 3, name: '19.5 cm' },
                { id: 4, name: '22.5 cm' },  
                { id: 5, name: '25.5 cm' },  
                { id: 6, name: '28.5 cm' },
                { id: 7, name: '31.5 cm' }
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Material: 201\nThickness(MM): 0.4'  
            },
            {  
                code: 2,  
                contact: 'Material: 201\nThickness(MM): 0.4'     
            },
            {  
                code: 3,  
                contact: 'Material: 201\nThickness(MM): 0.4'     
            },
            {  
                code: 4,  
                contact: 'Material: 201\nThickness(MM): 0.4\nQuantity / Box: 20\nOuter Box Specification(CM): 50*49*53'  
            },
            {  
                code: 5,  
                contact: 'Material: 201\nThickness(MM): 0.4\nQuantity / Box: 20\nOuter Box Specification(CM): 50*56*53'      
            },
            {  
                code: 6,  
                contact: 'Material: 201\nThickness(MM): 0.4'    
            },
            {  
                code: 7,  
                contact: 'Material: 201\nThickness(MM): 0.4'    
            },
        ]
    },
    {
        bySubcategory:15,//所属产品子类
        id:76,//产品ID
        name:'Rose Gold Multi-Purpose Royal Basket',//产品名
        img:['RoseGoldMulti-PurposeRoyalBasket_1.jpg','RoseGoldMulti-PurposeRoyalBasket_2.jpg','RoseGoldMulti-PurposeRoyalBasket_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '13.5 cm' },  
                { id: 2, name: '16.5 cm' },  
                { id: 3, name: '19.5 cm' },
                { id: 4, name: '22.5 cm' },  
                { id: 5, name: '25.5 cm' },  
                { id: 6, name: '28.5 cm' },
                { id: 7, name: '31.5 cm' }
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Material: 201\nThickness(MM): 0.4'  
            },
            {  
                code: 2,  
                contact: 'Material: 201\nThickness(MM): 0.4'     
            },
            {  
                code: 3,  
                contact: 'Material: 201\nThickness(MM): 0.4'     
            },
            {  
                code: 4,  
                contact: 'Material: 201\nThickness(MM): 0.4\nQuantity / Box: 20\nOuter Box Specification(CM): 50*49*53'  
            },
            {  
                code: 5,  
                contact: 'Material: 201\nThickness(MM): 0.4\nQuantity / Box: 20\nOuter Box Specification(CM): 50*56*53'      
            },
            {  
                code: 6,  
                contact: 'Material: 201\nThickness(MM): 0.4'    
            },
            {  
                code: 7,  
                contact: 'Material: 201\nThickness(MM): 0.4'    
            },
        ]
    },
    {
        bySubcategory:15,//所属产品子类
        id:77,//产品ID
        name:'Silver Multi-Purpose Royal Basket',//产品名
        img:['SilverMulti-PurposeRoyalBasket_1.jpg','SilverMulti-PurposeRoyalBasket_2.jpg','SilverMulti-PurposeRoyalBasket_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '13.5 cm' },  
                { id: 2, name: '16.5 cm' },  
                { id: 3, name: '19.5 cm' },
                { id: 4, name: '22.5 cm' },  
                { id: 5, name: '25.5 cm' },  
                { id: 6, name: '28.5 cm' },
                { id: 7, name: '31.5 cm' }
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Material: 201\nThickness(MM): 0.4'  
            },
            {  
                code: 2,  
                contact: 'Material: 201\nThickness(MM): 0.4'     
            },
            {  
                code: 3,  
                contact: 'Material: 201\nThickness(MM): 0.4'     
            },
            {  
                code: 4,  
                contact: 'Material: 201\nThickness(MM): 0.4\nQuantity / Box: 20\nOuter Box Specification(CM): 50*49*53'  
            },
            {  
                code: 5,  
                contact: 'Material: 201\nThickness(MM): 0.4\nQuantity / Box: 20\nOuter Box Specification(CM): 50*56*53'      
            },
            {  
                code: 6,  
                contact: 'Material: 201\nThickness(MM): 0.4'    
            },
            {  
                code: 7,  
                contact: 'Material: 201\nThickness(MM): 0.4'    
            },
        ]
    },

//Buffetware/Beverage Dispenser-5
    {
        bySubcategory:5,//所属产品子类
        id:35,//产品ID
        name:'Commercial Beverage Dispenser',//产品名
        img:['CommercialBeverageDispenser_1.jpg','CommercialBeverageDispenser_2.jpg','CommercialBeverageDispenser_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '8L' , isShowImg:true},  
                    { id: 2, name: '16L ' , isShowImg:true},  
                    { id: 3, name: '24L ' , isShowImg:true}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Product Size: 27cm*34cm*57cm\nMaterial: Stainless Steel / PE\nFunction: Refrigerated\nColor: Gold / Silver\nNet Weight (Single): 2.2 kg\nGross Weight (Single): 2.7 kg\nInner Box Specification: 42cm * 30cm * 29cm\nOuter Box Specification: 63cm * 44cm * 64cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 12 kg"  
            } ,
            {  
                code: 2,  
                contact: "Product Size: 56cm*34cm*57cm\nMaterial: Stainless Steel / PE\nFunction: Refrigerated\nColor: Gold / Silver\nNet Weight (Single): 4.5 kg\nGross Weight (Single): 5.5 kg\nInner Box Specification: 59cm*44cm*30cm\nOuter Box Specification: 61cm*47cm*64cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 12 kg"  
            } ,
            {  
                code: 3,  
                contact: "Product Size: 86cm*34cm*57cm\nMaterial: Stainless Steel / PE\nFunction: Refrigerated\nColor: Gold / Silver\nNet Weight (Single): 6.6 kg\nGross Weight (Single): 8 kg\nInner Box Specification: 89cm*45cm*33cm\nOuter Box Specification: 90cm*48cm*65cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 18 kg"  
            } ,
        ]
    },
    {
        bySubcategory:5,//所属产品子类
        id:36,//产品ID
        name:'Beverage Dispenser',//产品名
        img:['BeverageDispenser_1.jpg','BeverageDispenser_2.jpg','BeverageDispenser_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '3L' , isShowImg:true},  
                    { id: 2, name: '6L' , isShowImg:true},  
                    { id: 3, name: '9L' , isShowImg:true}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Product Size: 29cm*19cm*50cm*13cm\nMaterial: Stainless Steel / PE\nFunction: Refrigerated\nColor: Gold / Silver\nNet Weight (Single): 1.4kg\nGross Weight (Single): 1.9kg\nInner Box Specification: 32cm*15cm*36cm\nOuter Box Specification:  68cm*46cm*74cm\nPacking Quantity: 8 pieces/ Box\nGross Weight (Whole): 18kg"  
            } ,
            {  
                code: 2,  
                contact: "Product Size: 29cm*40cm*50cm*13cm\nMaterial: Stainless Steel / PE\nFunction: Refrigerated\nColor: Gold / Silver\nNet Weight (Single): 2.8kg\nGross Weight (Single): 3.8kg\nInner Box Specification: 42cm*34cm*30cm\nOuter Box Specification:  70cm*45cm*61cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 18kg"  
            } ,
            {  
                code: 3,  
                contact: "Product Size: 29cm*60cm*50cm*13cm\nMaterial: Stainless Steel / PE\nFunction: Refrigerated\nColor: Gold / Silver\nNet Weight (Single): 4.7kg\nGross Weight (Single): 5.3kg\nInner Box Specification: 64cm*34cm*31cm\nOuter Box Specification:  76cm*66cm*80cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 23kg"  
            } 
        ]
    },
    {
        bySubcategory:5,//所属产品子类
        id:37,//产品ID
        name:'8L Beverage Dispenser',//产品名
        img:['8LBeverageDispenser_1.jpg','8LBeverageDispenser_2.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: 'without sheet' },
                    { id: 2, name: 'with sheet' },
                    { id: 3, name: 'Hammered,without sheet' },
                    { id: 4, name: 'Hammered,with sheett' },
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel\nFunction: Refrigerated\nColor: Silver/ Rose Gold / Gold\nNet Weight (Single): 2.64kg\nGross Weight (Single): 3.2kg\nInner Box Specification: 45cm*28cm*30cm\nOuter Box Specification: 60cm*48cm*65cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 15kg"  
            },
            {  
                code: 2,  
                contact: "Material: Stainless Steel\nFunction: Refrigerated\nColor: Silver/ Rose Gold / Gold\nNet Weight (Single): 2.9kg\nGross Weight (Single): 3.4kg\nInner Box Specification: 45cm*28cm*30cm\nOuter Box Specification: 60cm*48cm*65cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 16kg"  
            },
            {  
                code: 3,  
                contact: "Material: Stainless Steel\nFunction: Refrigerated\nColor: Silver/ Rose Gold / Gold\nNet Weight (Single): 2.64kg\nGross Weight (Single): 3.2kg\nInner Box Specification: 45cm*28cm*30cm\nOuter Box Specification: 60cm*48cm*65cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 15kg"  
            },
            {  
                code: 4,  
                contact: "Material: Stainless Steel\nFunction: Refrigerated\nColor: Silver/ Rose Gold / Gold\nNet Weight (Single): 2.9kg\nGross Weight (Single): 3.4kg\nInner Box Specification: 45cm*28cm*30cm\nOuter Box Specification: 60cm*48cm*65cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 16kg"  
            } 
        ]
    },
    {
        bySubcategory:5,//所属产品子类
        id:38,//产品ID
        name:'12L Beverage Dispenser',//产品名
        img:['12LBeverageDispenser_1.jpg','12LBeverageDispenser_2.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: 'PC' , isShowImg:true},  
                    { id: 2, name: 'Stainless Steel' , isShowImg:true}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Product Size: 40cm*30cm*51cm\nMaterial: Stainless Steel / PC / PE\nFunction: Heated/ Refrigerated\nColor: Gold / Silver\nNet Weight (Single): 2.9kg\nGross Weight (Single): 3.7kg\nInner Box Specification: 35cm*35cm*43cm\nOuter Box Specification:  73cm*36cm*90cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 17kg"  
            } ,
            {  
                code: 2,  
                contact: "Product Size: 40cm*30cm*51cm\nMaterial: Stainless Steel / PE\nFunction: Heated/ Refrigerated\nColor: Gold / Silver\nNet Weight (Single): 3.2kg\nGross Weight (Single): 4kg\nInner Box Specification: 35cm*35cm*43cm\nOuter Box Specification:  73cm*36cm*90cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 18kg"  
            }  
        ]
    },
    {
        bySubcategory:5,//所属产品子类
        id:39,//产品ID
        name:'13L Beverage Dispenser',//产品名
        img:['13LBeverageDispenser_1.jpg','13LBeverageDispenser_2.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: 'General' ,isShowImg:true},
                    { id: 2, name: 'Custom Handle', isShowImg:true}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel\nFunction: Heated/ Refrigerated\nColor: Silver / Gold with handle / Gold\nNet Weight (Single): 2.2kg\nGross Weight (Single): 2.7kg\nInner Box Specification: 30cm*30cm*54cm\nOuter Box Specification: 92cm*62cm*57cm\nPacking Quantity: 6 pieces/ Box\nGross Weight (Whole): 20kg"  
            } ,
            {  
                code: 2,  
                contact: "Material: Stainless Steel\nFunction: Heated/ Refrigerated\nColor: Silver/ Rose Gold / Gold\nInner Box Specification: 30cm*30cm*54cm\nOuter Box Specification: 92cm*62cm*57cm\nPacking Quantity: 6 pieces/ Box"  
            } 
        ]
    },
    {
        bySubcategory:5,//所属产品子类
        id:40,//产品ID
        name:'16L Beverage Dispenser',//产品名
        img:['16LBeverageDispenser_1.jpg','16LBeverageDispenser_4.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: 'without sheet' },
                    { id: 2, name: 'with sheet' },
                    { id: 3, name: 'Hammered,without sheet' },
                    { id: 4, name: 'Hammered,with sheett' ,isShowImg:true},
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel\nFunction: Refrigerated\nColor: Silver/ Rose Gold / Gold\nNet Weight (Single): 5.2kg\nGross Weight (Single): 6.2kg\nInner Box Specification: 56cm*47cm*30cm\nOuter Box Specification: 60cm*47cm*65cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 15kg"  
            },
            {  
                code: 2,  
                contact: "Material: Stainless Steel\nFunction: Refrigerated\nColor: Silver/ Rose Gold / Gold\nNet Weight (Single): 5.8kg\nGross Weight (Single): 6.8kg\nInner Box Specification: 56cm*47cm*30cm\nOuter Box Specification: 60cm*47cm*65cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 16kg"  
            },
            {  
                code: 3,  
                contact: "Material: Stainless Steel\nFunction: Refrigerated\nColor: Silver/ Rose Gold / Gold\nNet Weight (Single): 5.2kg\nGross Weight (Single): 6.2kg\nInner Box Specification: 56cm*47cm*30cm\nOuter Box Specification: 60cm*47cm*65cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 15kg"  
            },
            {  
                code: 4,  
                contact: "Material: Stainless Steel\nFunction: Refrigerated\nColor: Silver/ Rose Gold / Gold\nNet Weight (Single): 5.8kg\nGross Weight (Single): 6.8kg\nInner Box Specification: 56cm*47cm*30cm\nOuter Box Specification: 60cm*47cm*65cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 16kg"  
            } 
        ]
    },
    {
        bySubcategory:5,//所属产品子类
        id:41,//产品ID
        name:'19L Beverage Dispenser',//产品名
        img:['19LBeverageDispenser_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: 'Stainless Steel' }
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel\nFunction: Heated/ Refrigerated\nColor: Gold / Silver\nNet Weight (Single): 3.4kg\nGross Weight (Single): 4.3kg\nInner Box Specification: 35cm*35cm*50cm\nOuter Box Specification: 72cm*72cm*53cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 19kg"  
            } 
        ]
    },
    {
        bySubcategory:5,//所属产品子类
        id:42,//产品ID
        name:'Clear Glass Beverage Dispenser',//产品名
        img:['ClearGlassBeverageDispenser_1.jpg','ClearGlassBeverageDispenser_2.jpg','ClearGlassBeverageDispenser_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '5L' ,isShowImg:true},
                    { id: 2, name: '7.5L',isShowImg:true},
                    { id: 3, name: '10L',isShowImg:true}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Wood / Clear Glass\nFunction: Storage\nColor: Natural Color\nNet Weight (Single): 2.5kg\nGross Weight (Single): 2.9kg\nInner Box Specification: 26cm*25cm*40cm\nOuter Box Specification: 80cm*55cm*45cm\nPacking Quantity: 6 pieces/ Box"  
            },
            {  
                code: 2,  
                contact: "Material: Wood / Clear Glass\nFunction: Storage\nColor: Natural Color\nNet Weight (Single): 3.1kg\nGross Weight (Single): 3.6kg\nInner Box Specification: 26cm*25cm*54cm\nOuter Box Specification: 80cm*55cm*54cm\nPacking Quantity: 6 pieces/ Box"  
            } ,
            {  
                code: 3,  
                contact: "Material: Wood / Clear Glass\nFunction: Storage\nColor: Natural Color\nNet Weight (Single): 3.8kg\nGross Weight (Single): 4.3kg\nInner Box Specification: 26cm*25cm*64cm\nOuter Box Specification: 80cm*55cm*65cm\nPacking Quantity: 6 pieces/ Box"  
            }  
        ]
    },
    {
        bySubcategory:5,//所属产品子类
        id:43,//产品ID
        name:'Stainless Steel Beverage Drink Dispenser Type A',//产品名
        img:['StainlessSteelBeverageDrinkDispenserTypeA_1.jpg','StainlessSteelBeverageDrinkDispenserTypeA_2.jpg','StainlessSteelBeverageDrinkDispenserTypeA_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '6L' },
                    { id: 2, name: '8L'},
                    { id: 3, name: '10L'},
                    { id: 4, name: '12L'}
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name:'Plastic parts: Red', isShowImg:true},
                    { id: 2, name:'Plastic parts: Brown', isShowImg:true},
                    { id: 3, name:'Plastic parts: Black', isShowImg:true},
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel\nFunction: Storage\nNet Weight (Single): 1.6/kg\nInner Box Specification: 26cm*26cm*33cm\nOuter Box Specification: 82cm*56cm*68cm\nPacking Quantity: 12 pieces/ Box"  
            },
            {  
                code: 2,  
                contact: "Material: Stainless Steel\nFunction: Storage\nNet Weight (Single): 1.7/kg\nInner Box Specification: 26cm*26cm*38cm\nOuter Box Specification: 82cm*56cm*79cm\nPacking Quantity: 12 pieces/ Box"  
            } ,
            {  
                code: 3,  
                contact: "Material: Stainless Steel\nFunction: Storage\nNet Weight (Single): 1.9/kg\nInner Box Specification: 26cm*26cm*44cm\nOuter Box Specification: 82cm*56cm*46cm\nPacking Quantity: 6 pieces/ Box"  
            },
            {  
                code: 4,  
                contact: "Material: Stainless Steel\nFunction: Storage\nNet Weight (Single): 2.1/kg\nInner Box Specification: 26cm*26cm*50cm\nOuter Box Specification: 82cm*56cm*52cm\nPacking Quantity: 6 pieces/ Box"  
            }    
        ]
    },
    {
        bySubcategory:5,//所属产品子类
        id:96,//产品ID
        name:'Stainless Steel Beverage Drink Dispenser Type B',//产品名
        img:['StainlessSteelBeverageDrinkDispenserTypeB_1.jpg','StainlessSteelBeverageDrinkDispenserTypeB_2.jpg','StainlessSteelBeverageDrinkDispenserTypeB_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '6L' },
                    { id: 2, name: '8L'},
                    { id: 3, name: '10L'},
                    { id: 4, name: '12L'}
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name:'Pink' , isShowImg:true},
                    { id: 2, name:'Blue' , isShowImg:true},
                    { id: 3, name:'White', isShowImg:true}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel\nFunction: Storage\nNet Weight (Single): 1.6/kg\nInner Box Specification: 26cm*26cm*33cm\nOuter Box Specification: 82cm*56cm*68cm\nPacking Quantity: 12 pieces/ Box"  
            },
            {  
                code: 2,  
                contact: "Material: Stainless Steel\nFunction: Storage\nNet Weight (Single): 1.7/kg\nInner Box Specification: 26cm*26cm*38cm\nOuter Box Specification: 82cm*56cm*79cm\nPacking Quantity: 12 pieces/ Box"  
            } ,
            {  
                code: 3,  
                contact: "Material: Stainless Steel\nFunction: Storage\nNet Weight (Single): 1.9/kg\nInner Box Specification: 26cm*26cm*44cm\nOuter Box Specification: 82cm*56cm*46cm\nPacking Quantity: 6 pieces/ Box"  
            },
            {  
                code: 4,  
                contact: "Material: Stainless Steel\nFunction: Storage\nNet Weight (Single): 2.1/kg\nInner Box Specification: 26cm*26cm*50cm\nOuter Box Specification: 82cm*56cm*52cm\nPacking Quantity: 6 pieces/ Box"  
            }    
        ]
    },
//Buffetware/Cereal Dispenser-6
    {
        bySubcategory:6,//所属产品子类
        id:44,//产品ID
        name:'Stainless Steel Cereal Dispenser',//产品名
        img:['StainlessSteelCerealDispenser_1.jpg','StainlessSteelCerealDispenser_2.jpg','StainlessSteelCerealDispenser_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '3.5L', isShowImg:true },
                    { id: 2, name: '7L' , isShowImg:true},
                    { id: 3, name: '10.5L' , isShowImg:true}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: PC+Stainless Steel\nFunction: Grain Separation\nColor: Silver\nNet Weight (Single): 1.55kg\nGross Weight (Single): 2.5kg\nInner Box Specification: 25cm*13cm*33cm\nOuter Box Specification: 64cm*36cm*78cm\nPacking Quantity: 6 pieces/ Box\nGross Weight (Whole): 17kg"  
            } ,
            {  
                code: 2,  
                contact: "Material: PC+Stainless Steel\nFunction: Grain Separation\nColor: Silver\nNet Weight (Single): 2.85kg\nGross Weight (Single): 4.5kg\nInner Box Specification: 36cm*39cm*35cm\nOuter Box Specification: 68cm*38cm*71cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 20kg"  
            } ,
            {  
                code: 3,  
                contact: "Material: PC+Stainless Steel\nFunction: Grain Separation\nColor: Silver\nNet Weight (Single): 4.25kg\nGross Weight (Single): 5.8kg\nInner Box Specification: 51.5cm*35cm*37.5cm\nOuter Box Specification: 53cm*38cm*78cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 13kg"  
            } 
        ]
    },
    {
        bySubcategory:6,//所属产品子类
        id:45,//产品ID
        name:'Stainless Steel Cereal Dispenser(New)',//产品名
        img:['StainlessSteelCerealDispenser(New)_1.jpg','StainlessSteelCerealDispenser(New)_2.jpg','StainlessSteelCerealDispenser(New)_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '3.5L', isShowImg:true },
                    { id: 2, name: '7L',isShowImg:true},
                    { id: 3, name: '10.5L',isShowImg:true}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: PC+Stainless Steel\nFunction: Grain Separation\nColor: Silver\nNet Weight (Single): 2.4kg \nGross Weight (Single): 3.2kg\nInner Box Specification: 35.5cm*22cm*34cm \nOuter Box Specification: 69cm*38cm*71cm\nPacking Quantity: 6 pieces/ Box\nGross Weight (Whole): 21kg"  
            } ,
            {  
                code: 2,  
                contact: "Material: PC+Stainless Steel\nFunction: Grain Separation\nColor: Silver\nNet Weight (Single): 3.7kg\nGross Weight (Single): 5.3kg\nInner Box Specification: 37cm*26.5cm*32.5cm\nOuter Box Specification: 56cm*40cm*69cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 23kg"  
            } ,
            {  
                code: 3,  
                contact: "Material: PC+Stainless Steel\nFunction: Grain Separation\nColor: Silver\nNet Weight (Single): 4.9kg\nGross Weight (Single): 6.2kg\nInner Box Specification: 52cm*34cm*32.5cm\nOuter Box Specification: 73cm*55cm*70cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 27kg"  
            } 
        ]
    },
    {
        bySubcategory:6,//所属产品子类
        id:46,//产品ID
        name:'Sapele Material Cereal Dispenser',//产品名
        img:['SapeleMaterialCerealDispenser_1.jpg','SapeleMaterialCerealDispenser_2.jpg','SapeleMaterialCerealDispenser_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '3.5L' , isShowImg:true },
                    { id: 2, name: '7L', isShowImg:true},
                    { id: 3, name: '10.5L', isShowImg:true}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: PC+ Sapele\nFunction: Grain Separation\nColor: Natural Color\nNet Weight (Single): 2.1kg\nGross Weight (Single): 2.6kg \nInner Box Specification: 39cm*18cm*35cm\nOuter Box Specification: 60cm*28cm*75cm\nPacking Quantity: 6 pieces/ Box\nGross Weight (Whole): 18kg"  
            } ,
            {  
                code:2,  
                contact: "Material: PC+ Sapele\nFunction: Grain Separation\nColor: Natural Color\nNet Weight (Single): 4.2kg \nGross Weight (Single): 5.2kg \nInner Box Specification: 39cm*29cm*37cm\nOuter Box Specification: 60cm*38cm*77cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 19kg"  
            } ,
            {  
                code: 3,  
                contact: "Material: PC+ Sapele\nFunction: Grain Separation\nColor: Natural Color\nNet Weight (Single): 6.3kg\nGross Weight (Single): 7.8kg \nInner Box Specification: 64cm*27.5cm*35cm\nOuter Box Specification: 67cm*31cm*75cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 18kg"  
            } 
        ]
    },
    {
        bySubcategory:6,//所属产品子类
        id:47,//产品ID
        name:'Hammered Cereal Dispenser with Sheet ',//产品名
        img:['HammeredCerealDispenserwithSheet_1_1.jpg','HammeredCerealDispenserwithSheet_2_1.jpg','HammeredCerealDispenserwithSheet_3_1.jpg','HammeredCerealDispenserwithSheet_1_2.jpg','HammeredCerealDispenserwithSheet_2_2.jpg','HammeredCerealDispenserwithSheet_3_2.jpg','HammeredCerealDispenserwithSheet_1_3.jpg','HammeredCerealDispenserwithSheet_2_3.jpg','HammeredCerealDispenserwithSheet_3_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '3.5L' , isShowImg:true},
                    { id: 2, name: '7L', isShowImg:true},
                    { id: 3, name: '10.5L' , isShowImg:true}
                ]
            },
            {
                id:2,
                name:'Color',
                items:[
                    { id: 1, name: 'Rose Gold', isShowImg:true},
                    { id: 2, name: 'Silver' , isShowImg:true},
                    { id: 3, name: 'Gold' , isShowImg:true}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: PC+Stainless Steel\nFunction: Grain Separation\nNet Weight (Single): 2.1kg\nGross Weight (Single): 2.6kg \nInner Box Specification: 39cm*18cm*35cm\nOuter Box Specification: 60cm*28cm*75cm\nPacking Quantity: 6 pieces/ Box\nGross Weight (Whole): 18kg"  
            } ,
            {  
                code:2,  
                contact: "Material: PC+Stainless Steel\nFunction: Grain Separation\nNet Weight (Single): 4.2kg \nGross Weight (Single): 5.2kg \nInner Box Specification: 39cm*29cm*37cm\nOuter Box Specification: 60cm*38cm*77cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 19kg"  
            } ,
            {  
                code: 3,  
                contact: "Material: PC+Stainless Steel\nFunction: Grain Separation\nNet Weight (Single): 6.3kg\nGross Weight (Single): 7.8kg \nInner Box Specification: 64cm*27.5cm*35cm\nOuter Box Specification: 67cm*31cm*75cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 18kg"  
            } 
        ]
    },
//Buffetware/Electric Soup Warmer-7
    {
        bySubcategory:7,//所属产品子类
        id:48,//产品ID
        name:'Electric Soup Warmer 10L ',//产品名
        img:['ElectricSoupWarmer10L_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: 'General, Black' }
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Product Size: Outside: 30cm*32cm,Inside: 23cm*22.5cm\nMaterial: Iron / Spray Paint\nFunction: Water-filled insulation / 30°-80°\nColor: Black\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 3.4kg\nGross Weight (Single): 4.2kg \nInner Box Specification: 38cm*38cm*39cm\nOuter Box Specification: 77cm*40cm*80cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 19kg"  
            }
        ]
    },
    {
        bySubcategory:7,//所属产品子类
        id:121,//产品ID
        name:'Intelligent Temperature Control Electric Soup Warmer 10L ',//产品名
        img:['IntelligentTemperatureControlElectricSoupWarmer10L_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: 'General, Black' }
                ]
            }
        ],
        describe:[
           
            {  
                code: 1,  
                contact: "Product Size: Outside: 30cm*32cm,Inside: 23cm*22.5cm\nMaterial: Iron / Spray Paint\nFunction: Water-filled insulation / 30°-80°\nColor: Black\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 3.4kg\nGross Weight (Single): 4.2kg \nInner Box Specification: 38cm*38cm*39cm\nOuter Box Specification: 77cm*40cm*80cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 19kg"  
            } 
        ]
    },
    {
        bySubcategory:7,//所属产品子类
        id:122,//产品ID
        name:'Stainless Steel Electric Soup Warmer 10L ',//产品名
        img:['StainlessSteelElectricSoupWarmer10L_1_1.jpg','StainlessSteelElectricSoupWarmer10L_1_2.jpg','StainlessSteelElectricSoupWarmer10L_1_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '10 L', isShowImg:true }
                ]
            },
            {
                id:2,
                name:'Color',
                items:[
                    { id: 1, name: 'General, Silver' , isShowImg:true},
                    { id: 2, name: 'General, Rose Gold' , isShowImg:true},
                    { id: 3, name: 'General, Gold' , isShowImg:true}
                ]
            }
        ],
        describe:[

            {  
                code: 1,  
                contact: "Product Size: Outside: 30cm*32cm,Inside: 23cm*22.5cm\nMaterial: Stainless Steel\nFunction: Water-filled insulation / 30°-80°\nColor: Natural Color / Rose Gold / Gold\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 3.4kg\nGross Weight (Single): 4.2kg \nInner Box Specification: 38cm*38cm*39cm\nOuter Box Specification: 77cm*40cm*80cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 19kg"  
            } ,
        ]
    },
    {
        bySubcategory:7,//所属产品子类
        id:49,//产品ID
        name:'Electric Soup Warmer 13L ',//产品名
        img:['ElectricSoupWarmer13L_1.jpg','ElectricSoupWarmer13L_2.jpg','ElectricSoupWarmer13L_3.jpg','ElectricSoupWarmer13L_4.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: 'General Type A' },
                    { id: 2, name: 'General Type B'},
                    { id: 3, name: 'Silver with Control'},
                    { id: 4, name: 'General, Silver'}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Product Size: Outside: 35cm*35cm,Inside: Inside: 30cm*21cm\nMaterial: Iron / Spray Paint\nFunction: Water-filled insulation / 30°-80°\nColor: Black\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 4.6kg\nGross Weight (Single): 5.4kg \nInner Box Specification: 44cm*45cm*40cm\nOuter Box Specification: 90cm*45cm*93cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 25kg"  
            } ,
            {  
                code: 2,  
                contact: "Product Size: Outside: 35cm*35cm,Inside: Inside: 30cm*21cm\nMaterial: Stainless Steel\nFunction: Water-filled insulation / 30°-80°\nColor: Natural Color\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 4.6kg\nGross Weight (Single): 5.4kg \nInner Box Specification: 44cm*45cm*40cm\nOuter Box Specification: 90cm*45cm*93cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 25kg"  
            } ,
            {  
                code: 3,  
                contact: "Product Size: Outside: 35cm*35cm,Inside: Inside: 30cm*21cm\nMaterial: Iron / Spray Paint\nFunction: Water-filled insulation / 30°-80°\nColor: Black\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 4.6kg\nGross Weight (Single): 5.9kg \nInner Box Specification: 44cm*45cm*42cm\nOuter Box Specification: 91cm*46cm*93cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 25kg"  
            } ,
            {  
                code: 4,  
                contact: "Product Size: Outside: 35cm*35cm,Inside: Inside: 30cm*21cm\nMaterial: Stainless Steel\nFunction: Water-filled insulation / 30°-80°\nColor: Natural Color\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 4.6kg\nGross Weight (Single): 5.9kg \nInner Box Specification: 44cm*45cm*42cm\nOuter Box Specification: 91cm*46cm*93cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 25kg"  
            } ,
        ]
    },
    {
        bySubcategory:7,//所属产品子类
        id:50,//产品ID
        name:'Electric Soup Warmer',//产品名
        img:['ElectricSoupWarmer_1_1.jpg','ElectricSoupWarmer_1_2.jpg','ElectricSoupWarmer_1_3.jpg','ElectricSoupWarmer_2_1.jpg','ElectricSoupWarmer_2_2.jpg','ElectricSoupWarmer_2_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '11L' ,isShowImg:true},
                    { id: 2, name: '13L',isShowImg:true}
                ]
            },
            {
                id:2,
                name:'Color',
                items:[
                    { id:1, name: 'Gold',isShowImg:true},
                    { id:2, name: 'Silver',isShowImg:true},
                    { id:3, name: 'Rose Gold',isShowImg:true}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Product Size: Outside: Outside: 27cm*31cm\nMaterial: Stainless Steel\nFunction: No-fill water insulation/30°-60°\nColor: Rose Gold / Gold / Silver\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 3.8kg\nGross Weight (Single): 4.3kg \nInner Box Specification: 34cm*34cm*39cm\nOuter Box Specification: 69cm*37cm*81cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 21kg"  
            } ,
            {  
                code: 2,  
                contact: "Product Size: Outside: Outside: 33.5cm*36cm\nMaterial: Stainless Steel\nFunction: Water-filled insulation/30°-70°\nColor: Rose Gold / Gold / Silver\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 4.7kg\nGross Weight (Single): 5.9kg \nInner Box Specification: 41cm*42cm*43cm\nOuter Box Specification: 84cm*42cm*89cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 26kg"  
            } 
        ]
    },
    {
        bySubcategory:7,//所属产品子类
        id:51,//产品ID
        name:'Insulated Stainless Steel Electric Soup Warmer',//产品名
        img:['InsulatedStainlessSteelElectricSoupWarmer_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '11L' }
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Product Size: Outside: 27cm*34.5cm,Inside: 23.5cm*23cm\nMaterial: Stainless Steel / Spray Paint\nFunction: No-fill water insulation/30°-60°\nColor: Yellow / Black / White / Red\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 3.6kg\nGross Weight (Single): 4.1kg \nInner Box Specification: 30cm*30cm*39cm\nOuter Box Specification: 92cm*33cm*81cm\nPacking Quantity: 6 pieces/ Box\nGross Weight (Whole): 27kg"  
            } 
        ]
    },
    {
        bySubcategory:7,//所属产品子类
        id:52,//产品ID
        name:'Intelligent Temperature Control Electric Soup Warmer 11L',//产品名
        img:['InsulatedStainlessSteelElectricSoupWarmer11L_1.jpg','InsulatedStainlessSteelElectricSoupWarmer11L_2.jpg','InsulatedStainlessSteelElectricSoupWarmer11L_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '11L' }
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name: 'Silver' , isShowImg:true},
                    { id: 2, name: 'Gold', isShowImg:true},
                    { id: 3, name: 'Rose Gold', isShowImg:true}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Product Size: Outside: 28.5cm*28.5cm*37cm,Inside: 23.5cm*22.7cm\nMaterial: Natural Color / Hammered Natural Color / Hammered Gold / Hammered Rose Glod\nFunction: Water-filled insulation / 30°-70°\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 4.2kg\nGross Weight (Single): 4.9kg \nInner Box Specification: 35cm*35cm*43cm\nOuter Box Specification: 73cm*27cm*91cm\nPacking Quantity: 4 pieces/ Box\nGross Weight (Whole): 22kg"  
            } 
        ]
    },
//Buffetware/Milk /Coffee Dispense-8
    {
        bySubcategory:8,//所属产品子类
        id:53,//产品ID
        name:'Intelligent Ceramic Milk Dispenser',//产品名
        img:['IntelligentCeramicMilkDispenser_1.jpg','IntelligentCeramicMilkDispenser_2.jpg','IntelligentCeramicMilkDispenser_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '10L'}
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name: 'Yellow' , isShowImg:true},
                    { id: 2, name: 'Green', isShowImg:true},
                    { id: 3, name: 'White', isShowImg:true}
                ]
            },
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Iron / Stainless Steel / Ceramics\nProduct Size: Outside: 25cm*19.5cm*36cmr\nFunction: 30°-70°\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 6kg\nGross Weight (Single): 8kg \nInner Box Specification: 38cm*31cm*53cm\nOuter Box Specification: 63cm*40cm*55cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 19kg"  
            }, 
        ]
    },
    {
        bySubcategory:8,//所属产品子类
        id:124,//产品ID
        name:'Intelligent Ceramic Milk Dispenser with Double Bottle',//产品名
        img:['IntelligentCeramicMilkDispenserwithDoubleBottle_1.jpg','IntelligentCeramicMilkDispenserwithDoubleBottle_2.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '10L' }
                ]
            },
        ],
        describe:[
            {  
                code: 1,  
                contact: "Color: White / Yellow / Green\nFunction: 30°-70°\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 11kg\nGross Weight (Single): 15kg \nInner Box Specification: 72cm*31cm*53cm\nPacking Quantity: 1 pieces/ Box"  
            } ,
        ]
    },
    {
        bySubcategory:8,//所属产品子类
        id:125,//产品ID
        name:'Intelligent Ceramic Milk Dispenser 5L',//产品名
        img:['IntelligentCeramicMilkDispenser5L_1.jpg','IntelligentCeramicMilkDispenser5L_2.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: 'Type A' },
                    { id: 2, name: 'Type B' },
                ]
            },
        ],
        describe:[
            {  
                code: 1,  
                contact: "Color: White / Colorful\nFunction: 30°-70°\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 5kg\nGross Weight (Single): 16kg \nInner Box Specification: 36cm*25cm*50cm\nOuter Box Specification: 54cm*38cm*54cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 15kg"  
            } ,
            {  
                code: 2,  
                contact: "Color: White / Colorful\nFunction: 30°-70°\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 9kg\nGross Weight (Single): 11kg \nInner Box Specification: 69cm*26cm*50cm\nPacking Quantity: 1 pieces/ Box"  
            }  
        ]
    },
    {
        bySubcategory:8,//所属产品子类
        id:54,//产品ID
        name:'Intelligent Hammered Ceramic Milk Dispenser 10L',//产品名
        img:['IntelligentHammeredCeramicMilkDispenser10L_1.jpg','IntelligentHammeredCeramicMilkDispenser10L_2.jpg','IntelligentHammeredCeramicMilkDispenser10L_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: 'Silver' },
                    { id: 2, name: 'Rose Gold' },
                    { id: 3, name: 'Gold' }
                ]
            },
        ],
        describe:[
            {  
                code: 1,  
                contact: "Function: 30°-70°\nMaterial: Stainless Steel / Ceramics\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 6kg\nGross Weight (Single): 8kg \nInner Box Specification: 38cm*31cm*53cm\nOuter Box Specification: 63cm*40cm*55cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 19kg"  
            } ,
            {  
                code: 2,  
                contact: "Function: 30°-70°\nMaterial: Stainless Steel / Ceramics\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 6kg\nGross Weight (Single): 8kg \nInner Box Specification: 38cm*31cm*53cm\nOuter Box Specification: 63cm*40cm*55cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 19kg"  
            } ,
            {  
                code: 3,  
                contact: "Function: 30°-70°\nMaterial: Stainless Steel / Ceramics\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 6kg\nGross Weight (Single): 8kg \nInner Box Specification: 38cm*31cm*53cm\nOuter Box Specification: 63cm*40cm*55cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 19kg"  
            } 
        ]
    },
    {
        bySubcategory:8,//所属产品子类
        id:123,//产品ID
        name:'Intelligent Hammered Ceramic Milk Dispenser 5L',//产品名
        img:['IntelligentHammeredCeramicMilkDispenser5L_1.jpg','IntelligentHammeredCeramicMilkDispenser5L_2.jpg','IntelligentHammeredCeramicMilkDispenser5L_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: 'Silver' },
                    { id: 2, name: 'Rose Gold' },
                    { id: 3, name: 'Gold' }
                ]
            },
        ],
        describe:[
            {  
                code: 1,  
                contact: "Function: 30°-70°\nMaterial: Stainless Steel / Ceramics\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 5kg\nGross Weight (Single): 6kg \nInner Box Specification: 36cm*25cm*50cm\nOuter Box Specification: 54cm*38cm*54cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 15kg"  
            } ,
            {  
                code: 2,  
                contact: "Function: 30°-70°\nMaterial: Stainless Steel / Ceramics\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 5kg\nGross Weight (Single): 6kg \nInner Box Specification: 36cm*25cm*50cm\nOuter Box Specification: 54cm*38cm*54cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 15kg"  
            } ,
            {  
                code: 3,  
                contact: "Function: 30°-70°\nMaterial: Stainless Steel / Ceramics\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 5kg\nGross Weight (Single): 6kg \nInner Box Specification: 36cm*25cm*50cm\nOuter Box Specification: 54cm*38cm*54cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 15kg"  
            } , 
        ]
    },
    {
        bySubcategory:8,//所属产品子类
        id:55,//产品ID
        name:'Sapele Solid Wood base portable Milk Dispenser 10L',//产品名
        img:['SapeleSolidWoodbaseportableMilkDispenser10L_1.jpg','SapeleSolidWoodbaseportableMilkDispenser10L_2.jpg','SapeleSolidWoodbaseportableMilkDispenser10L_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: 'White' },
                    { id: 2, name: 'Yellow' },
                    { id: 3, name: 'Green' }
                ]
            },
        ],
        describe:[
            {  
                code: 1,  
                contact: "Function: 30°-60°\nMaterial: Sapele / Stainless Steel / Ceramics\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 6.5kg\nGross Weight (Single): 7.5kg \nInner Box Specification: 40cm*39cm*55cm\nOuter Box Specification: 62cm*42cm*58cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 18kg"  
            } ,
            {  
                code: 2,  
                contact: "Function: 30°-60°\nMaterial: Sapele / Stainless Steel / Ceramics\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 6.5kg\nGross Weight (Single): 7.5kg \nInner Box Specification: 40cm*39cm*55cm\nOuter Box Specification: 62cm*42cm*58cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 18kg"  
            } ,
            {  
                code: 3,  
                contact: "Function: 30°-60°\nMaterial: Sapele / Stainless Steel / Ceramics\nVoltage / Wattage: 220V 400W\nNet Weight (Single): 6.5kg\nGross Weight (Single): 7.5kg \nInner Box Specification: 40cm*39cm*55cm\nOuter Box Specification: 62cm*42cm*58cm\nPacking Quantity: 2 pieces/ Box\nGross Weight (Whole): 18kg"  
            } 
        ]
    },
//Buffetware/Chafing Dish-9
    {
        bySubcategory:9,//所属产品子类
        id:98,//产品ID
        name:'5L Roll Top Chafing Dish Buffet Food Warmer',//产品名
        img:['5LRollTopChafingDishBuffetFoodWarmer_1.jpg'],//产品图片名，不需要写路径，
        note:'Electric Heating Plate in Standard Package',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '5 L' },
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name: 'Silver'}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel\nNet Weight (Single): 4.5kg\nGross Weight (Single): 5.5kg \nInner Box Specification: 37cm*36cm*28cm\nPacking Quantity: 1 pieces/ Box"  
            }
        ]
    },
    {
        bySubcategory:9,//所属产品子类
        id:99,//产品ID
        name:'5L Intelligent Roll Top Chafing Dish Buffet Food Warmer',//产品名
        img:['5LIntelligentRollTopChafingDishBuffetFood Warmer_1.jpg'],//产品图片名，不需要写路径，
        note:'Electric Heating Plate in Standard Package',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '5 L' },
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name: 'Silver'}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel\nNet Weight (Single): 4.5kg\nGross Weight (Single): 5.5kg \nInner Box Specification: 37cm*36cm*28cm\nPacking Quantity: 1 pieces/ Box"  
            }
        ]
    },
    {
        bySubcategory:9,//所属产品子类
        id:97,//产品ID
        name:'9L Intelligent Roll Top Chafing Dish Buffet Food Warmer',//产品名
        img:['9LIntelligentRollTopChafingDishBuffetFoodWarmer_1.jpg'],//产品图片名，不需要写路径，
        note:'Electric Heating Plate in Standard Package',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '9 L' },
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name: 'Silver'}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel\nNet Weight (Single): 8.3kg\nGross Weight (Single): 9.8kg \nInner Box Specification: 58cm*43cm*29cm\nPacking Quantity: 1 pieces/ Box"  
            }
        ]
    },
    {
        bySubcategory:9,//所属产品子类
        id:100,//产品ID
        name:'9L 410 Roll Top Chafing Dish Buffet Food Warmer',//产品名
        img:['9L410RollTopChafingDishBuffetFoodWarmer_1.jpg','9L410RollTopChafingDishBuffetFoodWarmer_2.jpg'],//产品图片名，不需要写路径，
        note:'Alcohol Stove in Standard Package',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '9 L , 410' }
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name: 'Silver' , isShowImg:true},
                    { id: 2, name: 'Gold' , isShowImg:true}
                ]
            },
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel\nNet Weight (Single): 4kg\nGross Weight (Single): 4.7kg\nInner Box Specification: 62cm*37cm*14cm\nPacking Quantity: 1 pieces/ Box"  
            }
        ]
    },
    {
        bySubcategory:9,//所属产品子类
        id:101,//产品ID
        name:'9L 201 Roll Top Chafing Dish Buffet Food Warmer',//产品名
        img:['9L201RollTopChafingDishBuffetFoodWarmer_1.jpg'],//产品图片名，不需要写路径，
        note:'Alcohol Stove in Standard Package',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '9 L , 201' }
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name: 'Silver'}
                ]
            },
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel\nNet Weight (Single): 4kg\nGross Weight (Single): 4.7kg\nInner Box Specification: 62cm*37cm*14cm\nPacking Quantity: 1 pieces/ Box"  
            },
        ]
    },
    {
        bySubcategory:9,//所属产品子类
        id:56,//产品ID
        name:'9L Roll Top Chafing Dish Buffet Food Warmer Type A',//产品名
        img:['9LRollTopChafingDishBuffetFoodWarmerTypeA_1.jpg'],//产品图片名，不需要写路径，
        note:'Electric Heating Plate in Standard Package',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '9 L' },
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name: 'Silver'}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel\nNet Weight (Single): 8.3kg\nGross Weight (Single): 9.8kg \nInner Box Specification: 58cm*43cm*29cm\nPacking Quantity: 1 pieces/ Box"  
            }
        ]
    },
    {
        bySubcategory:9,//所属产品子类
        id:102,//产品ID
        name:'9L Roll Top Chafing Dish Buffet Food Warmer Type B',//产品名
        img:['9LRollTopChafingDishBuffetFoodWarmerTypeB_1.jpg','9LRollTopChafingDishBuffetFoodWarmerTypeB_2.jpg'],//产品图片名，不需要写路径，
        note:'Alcohol Stove in Standard Package',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '9 L' },
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name: 'Silver' , isShowImg:true},
                    { id: 2, name: 'Gold' , isShowImg:true}
                ]
            },
        ],
        describe:[

            {  
                code: 1,  
                contact: "Material: Stainless Steel + Glass\nNet Weight (Single): 7.6kg\nGross Weight (Single): 9kg \nInner Box Specification: 67cm*47cm*30cm\nPacking Quantity: 1 pieces/ Box"  
            },
        ]
    },
    {
        bySubcategory:9,//所属产品子类
        id:103,//产品ID
        name:'9L Roll Top Chafing Dish Buffet Food Warmer Type C',//产品名
        img:['9LRollTopChafingDishBuffetFoodWarmerTypeC_1.jpg'],//产品图片名，不需要写路径，
        note:'Alcohol Stove in Standard Package',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '9 L' },
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name: 'Silver'}
                ]
            },
        ],
        describe:[

            {  
                code: 1,  
                contact: "Material: Stainless Steel + Glass\nNet Weight (Single): 7.6kg\nGross Weight (Single): 9kg \nInner Box Specification: 67cm*47cm*30cm\nPacking Quantity: 1 pieces/ Box"  
            },
        ]
    },
    {
        bySubcategory:9,//所属产品子类
        id:104,//产品ID
        name:'9L Roll Top Chafing Dish Buffet Food Warmer Type D',//产品名
        img:['9LRollTopChafingDishBuffetFoodWarmerTypeD_1.jpg'],//产品图片名，不需要写路径，
        note:'Alcohol Stove in Standard Package',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '9 L' },
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name: 'Silver'}
                ]
            },
        ],
        describe:[

            {  
                code: 1,  
                contact: "Material: Stainless Steel + Glass\nNet Weight (Single): 6.8kg\nGross Weight (Single): 8.6kg\nInner Box Specification: 59cm*45cm*24cm\nPacking Quantity: 1 pieces/ Box"  
            },
            
        ]
    },
    {
        bySubcategory:9,//所属产品子类
        id:107,//产品ID
        name:'9L Roll Top Chafing Dish Buffet Food Warmer Type E',//产品名
        img:['9LRollTopChafingDishBuffetFoodWarmerTypeE_1.jpg'],//产品图片名，不需要写路径，
        note:'Alcohol Stove in Standard Package',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '9 L' },
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name: 'Silver'}
                ]
            },
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel + Glass\nNet Weight (Single): 6.8kg\nGross Weight (Single): 8.6kg \nInner Box Specification: 62cm*47cm*40cm\nPacking Quantity: 1 pieces/ Box"  
            },
          
        ]
    },
    {
        bySubcategory:9,//所属产品子类
        id:105,//产品ID
        name:'6L Roll Top Chafing Dish Buffet Food Warmer Tpye A',//产品名
        img:['6LRollTopChafingDishBuffetFoodWarmerTypeA_1.jpg'],//产品图片名，不需要写路径，
        note:'Alcohol Stove in Standard Package',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '6 L' },
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name: 'Silver'}
                ]
            },
        ],
        describe:[
           
            {  
                code: 1,  
                contact: "Material: Stainless Steel + Glass\nNet Weight (Single): 5.4kg\nGross Weight (Single): 6.7kg\nInner Box Specification: 44cm*45cm*24cm\nPacking Quantity: 1 pieces/ Box"  
            },
        ]
    },
    {
        bySubcategory:9,//所属产品子类
        id:106,//产品ID
        name:'6L Roll Top Chafing Dish Buffet Food Warmer Tpye B',//产品名
        img:['6LRollTopChafingDishBuffetFoodWarmerTypeB_1.jpg'],//产品图片名，不需要写路径，
        note:'Alcohol Stove in Standard Package',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '6 L' },
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name: 'Silver'}
                ]
            },
        ],
        describe:[
           
            {  
                code: 1,  
                contact: "Material: Stainless Steel + Glass\nNet Weight (Single): 5.6kg\nGross Weight (Single): 6.8kg \nInner Box Specification: 45cm*45cm*24cm\nPacking Quantity: 1 pieces/ Box"  
            },
        ]
    },
    {
        bySubcategory:9,//所属产品子类
        id:108,//产品ID
        name:'6L Roll Top Chafing Dish Buffet Food Warmer Type C',//产品名
        img:['6LRollTopChafingDishBuffetFoodWarmerTypeC_1.jpg'],//产品图片名，不需要写路径，
        note:'Alcohol Stove in Standard Package',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '6 L' },
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name: 'Silver'}
                ]
            },
        ],
        describe:[
            
            {  
                code: 1,  
                contact: "Material: Stainless Steel + Glass\nNet Weight (Single): 5.4kg\nGross Weight (Single): 6.7kg \nInner Box Specification: 45cm*45cm*39cm\nPacking Quantity: 1 pieces/ Box"  
            },
           
        ]
    },
    {
        bySubcategory:25,//所属产品子类
        id:109,//产品ID
        name:'6L Roll Top Chafing Dish Buffet Food Warmer Type D',//产品名
        img:['6LRollTopChafingDishBuffetFoodWarmerTypeD_1.jpg'],//产品图片名，不需要写路径，
        note:'Alcohol Stove in Standard Package',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  

                    { id: 1, name: '6 L' }
                ]
            },
            {
                id: 2,
                name: 'Color',
                items:[
                    { id: 1, name: 'Silver'}
                ]
            },
        ],
        describe:[
           
            {  
                code: 1,  
                contact: "Material: Stainless Steel + Glass\nNet Weight (Single): 5.6kg\nGross Weight (Single): 6.8kg \nInner Box Specification: 51cm*48cm*41cm\nPacking Quantity: 1 pieces/ Box"  
            },
        ]
    },  
    {
        bySubcategory:25,//所属产品子类
        id:111,//产品ID
        name:'4.5L Round Stainless Steel Chafing Dish Buffet with Top Lid',//产品名
        img:['45LRoundStainlessSteelChafingDishBuffetwithTopLid_1.jpg','45LRoundStainlessSteelChafingDishBuffetwithTopLid_2.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: ' 4.5 L' }
                ]
            },
            {
                id:2,
                name:'Color',
                items:[
                    { id:1 , name: 'Silver',isShowImg:true},
                    { id:2 , name: 'Gold',isShowImg:true}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel + Glass\nNet Weight (Single): 5.5kg\nGross Weight (Single):6.4kg\nInner Box Specification: 42cm*42cm*14cm\nPacking Quantity: 1 pieces/ Box"           
            },
        ]
    },
    {
        bySubcategory:25,//所属产品子类
        id:112,//产品ID
        name:'4.5L Round Stainless Steel Chafing Dish Buffet with Top Lid in Stainless Steel',//产品名
        img:['45LRoundStainlessSteelChafingDishBuffetwithTopLidinStainlessSteel_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: ' 4.5 L' }
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel + Glass\nColor: Natural Color / Gold\nNet Weight (Single): 2.1kg\nGross Weight (Single): 2.7kg \nInner Box Specification: 36cm*36cm*29cm\nPacking Quantity: 1 pieces/ Box" 
            },
        ]
    },
    {
        bySubcategory:25,//所属产品子类
        id:113,//产品ID
        name:'4.5L Round Stainless Steel Chafing Dish Buffet with Top Lid in Glass',//产品名
        img:['45LRoundStainlessSteelChafingDishBuffetwithTopLidinStainGlass_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: ' 4.5 L' }
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel + Glass\nColor: Natural Color / Gold\nNet Weight (Single): 2.1kg\nGross Weight (Single): 2.7kg \nInner Box Specification: 36cm*36cm*29cm\nPacking Quantity: 1 pieces/ Box" 
            },
        ]
    },
    {
        bySubcategory:25,//所属产品子类
        id:114,//产品ID
        name:'9L Stainless Steel Chafing Dish Buffet with Top Lid Type A',//产品名
        img:['9LStainlessSteelChafingDishBuffetwithTopLidTypeA_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: ' 9 L' }
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel\nColor: Silver\nInner Box Specification: 58cm*37cm*21cm\nPacking Quantity: 1 pieces/ Box" 
            },
        ]
    },
    {
        bySubcategory:25,//所属产品子类
        id:115,//产品ID
        name:'9L Stainless Steel Chafing Dish Buffet with Top Lid Type B',//产品名
        img:['9LStainlessSteelChafingDishBuffetwithTopLidTypeB_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: ' 9 L' }
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel + PC\nColor: Silver\nInner Box Specification: 58cm*37cm*21cm\nPacking Quantity: 1 pieces/ Box" 
            },
        ]
    },
    {
        bySubcategory:25,//所属产品子类
        id:116,//产品ID
        name:'Chafing Dish Buffet with Top Lid and Two Compartments',//产品名
        img:['ChafingDishBuffetwithTopLidandTwoCompartments_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: 'Stainless Steel Lid' },
                    { id: 2, name: 'Glass Lid'}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel + Glass\nColor: Silver\nInner Box Specification: 58cm*37cm*21cm\nPacking Quantity: 1 pieces/ Box" 
            },
        ]
    },
    {
        bySubcategory:25,//所属产品子类
        id:110,//产品ID
        name:'6L Round Stainless Steel Chafing Dish Buffet with Top Lid Type A',//产品名
        img:['6LRoundStainlessSteelChafingDishBuffetwithTopLidTypeA_1.jpg','6LRoundStainlessSteelChafingDishBuffetwithTopLidTypeA_2.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: ' 6 L' }
                ]
            },
            {
                id:2,
                name:'Color',
                items:[
                    { id:1 , name: 'Silver',isShowImg:true},
                    { id:2 , name: 'Gold',isShowImg:true}
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel + Glass\nNet Weight (Single): 5.5kg\nGross Weight (Single):6.4kg\nInner Box Specification: 45cm*44cm*34cm\nPacking Quantity: 1 pieces/ Box"           
            },
        ]
    },
    {
        bySubcategory:25,//所属产品子类
        id:117,//产品ID
        name:'6L Round Stainless Steel Chafing Dish Buffet with Top Lid Type B',//产品名
        img:['6LRoundStainlessSteelChafingDishBuffetwithTopLidTypeB_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: ' 6 L' }
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel\nColor: Silver / Gold\nNet Weight (Single): 5.2kg\nGross Weight (Single): 6.4kg\nInner Box Specification: 49cm*49cm*29cm\nPacking Quantity: 1 pieces/ Box"  
            }
        ]
    },
    {
        bySubcategory:25,//所属产品子类
        id:118,//产品ID
        name:'6L Round Stainless Steel Chafing Dish Buffet with Top Lid Type C',//产品名
        img:['6LRoundStainlessSteelChafingDishBuffetwithTopLidTypeC_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: ' 6 L' }
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel\nColor: Silver / Gold\nNet Weight (Single): 5.2kg\nGross Weight (Single): 6.4kg\nInner Box Specification: 49cm*49cm*29cm\nPacking Quantity: 1 pieces/ Box"  
            }
        ]
    },
    {
        bySubcategory:25,//所属产品子类
        id:60,//产品ID
        name:'Round Stainless Steel Chafing Dish Buffet with Top Lid-Type A',//产品名
        img:['RoundStainlessSteelChafingDishBuffetwithTopLidTypeA_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '4.5L' },
                    { id: 2, name: '6L' },
                    { id: 3, name: '8L' }
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel\nColor: Gold\nNet Weight (Single): 3.7kg \nGross Weight (Single):4kg \nInner Box Specification: 72cm*36cm*46cm\nGross Weight (Whole): 17kg"  
            },
            {  
                code: 2,  
                contact: "Material: Stainless Steel\nColor: Gold\nNet Weight (Single): 4kg\nGross Weight (Single): 4.3kg \nInner Box Specification: 75cm*38cm*46cm\nGross Weight (Whole): 18kg"  
            },
            {  
                code: 3,  
                contact: "Material: Stainless Steel\nColor: Gold\nNet Weight (Single): 4.4kg\nGross Weight (Single): 4.7kg \nInner Box Specification: 77cm*39cm*47cm\nGross Weight (Whole): 20kg"  
            }
        ]
    },
    {
        bySubcategory:25,//所属产品子类
        id:61,//产品ID
        name:'Round Stainless Steel Chafing Dish Buffet with Top Lid-Type B',//产品名
        img:['RoundStainlessSteelChafingDishBuffetwithTopLidTypeB_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
            {
                id:1,//产品属性ID，每个不同产品，这个都从1开始,
                name:'Specifications',
                items: [  
                    { id: 1, name: '4.5L' },
                    { id: 2, name: '6L' },
                    { id: 3, name: '8L' }
                ]
            }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Material: Stainless Steel\nColor: Gold\nNet Weight (Single): 3.7kg \nInner Box Specification: 72cm*36cm*46cm\nPacking Quantity: 4 pieces / box\nGross Weight (Whole): 17kg"  
            },
            {  
                code: 2,  
                contact: "Material: Stainless Steel\nColor: Gold\nNet Weight (Single): 4kg\nInner Box Specification: 75cm*38cm*46cm\nPacking Quantity: 4 pieces / box\nGross Weight (Whole): 18kg"  
            },
            {  
                code: 3,  
                contact: "Material: Stainless Steel\nColor: Gold\nNet Weight (Single): 4.4kg\nInner Box Specification: 77cm*39cm*47cm\nPacking Quantity: 4 pieces / box\nGross Weight (Whole): 20kg"  
            }
        ]
    },
//Barware 4/Stainless Steel Ice Bucket-16
    {
        bySubcategory:16,//所属产品子类
        id:78,//产品ID
        name:'Stainless Steel Ice Bucket Type A',//产品名
        img:['StainlessSteelIceBucketTypeA_1.jpg','StainlessSteelIceBucketTypeA_2.jpg','StainlessSteelIceBucketTypeA_3.jpg','StainlessSteelIceBucketTypeA_4.jpg','StainlessSteelIceBucketTypeA_5.jpg','StainlessSteelIceBucketTypeA_6.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '5 L' }
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Diameter(CM): 25\nBottom Diameter(CM): 4.1\nHeight(CM): 18'  
            }
        ]
    },
    {
        bySubcategory:16,//所属产品子类
        id:79,//产品ID
        name:'Stainless Steel Ice Bucket Type B',//产品名
        img:['StainlessSteelIceBucketTypeB_1.jpg','StainlessSteelIceBucketTypeB_2.jpg','StainlessSteelIceBucketTypeB_3.jpg','StainlessSteelIceBucketTypeB_4.jpg','StainlessSteelIceBucketTypeB_5.jpg','StainlessSteelIceBucketTypeB_6.jpg','StainlessSteelIceBucketTypeB_7.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '5 L' }
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Diameter(CM): 25\nBottom Diameter(CM): 4.1\nHeight(CM): 18'  
            }
        ]
    },
    {
        bySubcategory:16,//所属产品子类
        id:80,//产品ID
        name:'Stainless Steel Ice Bucket Type C',//产品名
        img:['StainlessSteelIceBucketTypeC_1.jpg','StainlessSteelIceBucketTypeC_2.jpg','StainlessSteelIceBucketTypeC_3.jpg','StainlessSteelIceBucketTypeC_4.jpg','StainlessSteelIceBucketTypeC_5.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '1.5 L' },
                { id: 2, name: '2.0 L' },
                { id: 3, name: '2.5 L' },
                { id: 4, name: '3.0 L' },
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Diameter(CM): 15.8\nBottom Diameter(CM): 12\nHeight(CM): 24.7'  
            },
            {  
                code: 2,  
                contact: 'Diameter(CM): 18\nBottom Diameter(CM): 13.2\nHeight(CM): 26.5'  
            },
            {  
                code: 3,  
                contact: 'Diameter(CM): 19.5\nBottom Diameter(CM): 15\nHeight(CM): 29'  
            },
            {  
                code: 4,  
                contact: 'Diameter(CM): 22.5\nBottom Diameter(CM): 16.8\nHeight(CM): 30.5'  
            },
        ]
    },
    {
        bySubcategory:16,//所属产品子类
        id:81,//产品ID
        name:'Stainless Steel Ice Bucket Type D',//产品名
        img:['StainlessSteelIceBucketTypeD_1.jpg','StainlessSteelIceBucketTypeD_2.jpg','StainlessSteelIceBucketTypeD_3.jpg','StainlessSteelIceBucketTypeD_4.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '3 L' },
                { id: 2, name: '5 L' },
                { id: 3, name: '7 L' },
                { id: 4, name: '9 L' },
                { id: 5, name: '11 L' },
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Diameter(CM): 19\nBottom Diameter(CM): 14.8\nHeight(CM): 19'  
            },
            {  
                code: 2,  
                contact: 'Diameter(CM): 21.5\nBottom Diameter(CM): 16.5\nHeight(CM): 21.5'  
            },
            {  
                code: 3,  
                contact: 'Diameter(CM): 23\nBottom Diameter(CM): 18\nHeight(CM): 22'  
            },
            {  
                code: 4,  
                contact: 'Diameter(CM): 25.5\nBottom Diameter(CM): 20\nHeight(CM): 23.5'  
            },
            {  
                code: 5,  
                contact: 'Diameter(CM): 27\nBottom Diameter(CM): 22\nHeight(CM): 24.8'  
            },
        ]
    },
    {
        bySubcategory:16,//所属产品子类
        id:82,//产品ID
        name:'Stainless Steel Ice Bucket Type E',//产品名
        img:['StainlessSteelIceBucketTypeE_1.jpg','StainlessSteelIceBucketTypeE_2.jpg','StainlessSteelIceBucketTypeE_3.jpg','StainlessSteelIceBucketTypeE_1.jpg','StainlessSteelIceBucketTypeE_4.jpg','StainlessSteelIceBucketTypeE_5.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '0.8 L' },
                { id: 2, name: '1 L' },
                { id: 3, name: '1.5 L' },
                { id: 4, name: '2 L' },
                { id: 5, name: '2.5 L' },
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Diameter(CM): 13\nBottom Diameter(CM): 8.5\nHeight(CM): 10'  
            },
            {  
                code: 2,  
                contact: 'Diameter(CM): 14\nBottom Diameter(CM): 9\nHeight(CM): 12'  
            },
            {  
                code: 3,  
                contact: 'Diameter(CM): 15.5\nBottom Diameter(CM): 10\nHeight(CM): 13'  
            },
            {  
                code: 4,  
                contact: 'Diameter(CM): 16.5\nBottom Diameter(CM): 10.8\nHeight(CM): 15'  
            },
            {  
                code: 5,  
                contact: 'Diameter(CM): 17.5\nBottom Diameter(CM): 12\nHeight(CM): 16.5'  
            },
        ]
    },
//Barware 4/Stainless Steel Ice Shovel-17
    {
        bySubcategory:17,//所属产品子类
        id:83,//产品ID
        name:'Stainless Steel Ice Shovel',//产品名
        img:['StainlessSteelIceShovel_1.jpg','StainlessSteelIceShovel_2.jpg','StainlessSteelIceShovel_3.jpg','StainlessSteelIceShovel_4.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Small' },
                { id: 2, name: 'Medium' },
                { id: 3, name: 'Large' }
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Long(CM): 27\nWide(CM): 12'  
            },
            {  
                code: 2,  
                contact: 'Long(CM): 25\nWide(CM): 9.5'   
            },
            {  
                code: 3,  
                contact: 'Long(CM): 22\nWide(CM): 9'    
            }
        ]
    },
//Catering 5/Barrel-18
    {
        bySubcategory:18,//所属产品子类
        id:84,//产品ID
        name:'Barrel',//产品名
        img:['Barrel_1.jpg','Barrel_2.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Type A (5.5 kilograms of Water)' },
                { id: 2, name: 'Type B (11 kilograms of Water)' },
                { id: 3, name: 'Type C (20 kilograms of Water)' },
                { id: 4, name: 'Type D (35 kilograms of Water)' },
                { id: 5, name: 'Type E (45 kilograms of Water)' },
                { id: 6, name: 'Type F (65 kilograms of Water)' },
                { id: 7, name: 'Type G (90 kilograms of Water)' },
                { id: 8, name: 'Type H (150 kilograms of Water)' },
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Diameter(CM): 20\nHeight(CM): 20'  
            },
            {  
                code: 2,  
                contact: 'Diameter(CM): 25\nHeight(CM): 25'  
            },
            {  
                code: 3,  
                contact: 'Diameter(CM): 30\nHeight(CM): 30'  
            },
            {  
                code: 4,  
                contact: 'Diameter(CM): 35\nHeight(CM): 35'  
            },
            {  
                code: 5,  
                contact: 'Diameter(CM): 40\nHeight(CM): 40'  
            },
            {  
                code: 6,  
                contact: 'Diameter(CM): 45\nHeight(CM): 45'  
            },
            {  
                code: 7,  
                contact: 'Diameter(CM): 50\nHeight(CM): 50'  
            },
            {  
                code: 8,  
                contact: 'Diameter(CM): 60\nHeight(CM): 60'  
            },
        ]
    },
    {
        bySubcategory:18,//所属产品子类
        id:92,//产品ID
        name:'Seasoning Jar',//产品名
        img:['SeasoningJar_1.jpg','SeasoningJar_2.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Type A' , isShowImg:true},
                { id: 2, name: 'Tpey B' , isShowImg:true}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'For more precise information, contact us at info@lomonhoteless.com'  
            },
            {  
                code: 2,  
                contact: 'For more precise information, contact us at info@lomonhoteless.com'  
            },
        ]
    },
//Catering 5/Straw-19
    {
        bySubcategory:19,//所属产品子类
        id:85,//产品ID
        name:'Straw',//产品名
        img:['Straw_1.jpg','Straw_2.jpg','Straw_3.jpg','Straw_4.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Straight Straw' , isShowImg:true},
                { id: 2, name: 'Curved Straw' , isShowImg:true},
                { id: 3, name: 'Straw Brush' , isShowImg:true},
                { id: 4, name: 'Bag' , isShowImg:true}
            ]
        },
        ],
        describe:[
            {  
                code: 1,  
                contact: 'For more precise information, contact us at info@lomonhoteless.com'  
            },
            {  
                code: 2,  
                contact: 'For more precise information, contact us at info@lomonhoteless.com'  
            },
            {  
                code: 3,  
                contact: 'For more precise information, contact us at info@lomonhoteless.com'  
            },
            {  
                code: 4,  
                contact: 'For more precise information, contact us at info@lomonhoteless.com'  
            },
        ]
    },
//Catering 5/Vacuum Kettle-20
    {
        bySubcategory:20,//所属产品子类
        id:86,//产品ID
        name:'Multicolor Vacuum Kettle',//产品名
        img:['MulticolorVacuumKettle_1.jpg','MulticolorVacuumKettle_2.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Type A'},
                { id: 2, name: 'Type B'},
                { id: 3, name: 'Type C'},
                { id: 4, name: 'Type D'}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Capacity: 1.8 L / 2.3 L\nPackage: 24 pcs\nCarton Size(CM): 63*48*56 / 65*49*62'  
            },
            {  
                code: 2,  
                contact: 'Capacity: 1.8 L / 2.3 L\nPackage: 24 pcs\nCarton Size(CM): 63*48*56 / 65*49*62'   
            },
            {  
                code: 3,  
                contact: 'Capacity: 1.5 L / 2.0 L\nPackage: 24 pcs\nCarton Size(CM): 61*46*65 / 62*48*61'    
            },
            {  
                code: 4,  
                contact: 'Capacity: 1.5 L / 2.0 L\nPackage: 24 pcs\nCarton Size(CM): 61*47.5*60 / 62*48*61'    
            },
        ]
    },
    {
        bySubcategory:20,//所属产品子类
        id:87,//产品ID
        name:'Vacuum Insulated Teapot',//产品名
        img:['VacuumInsulatedTeapot_1.jpg','VacuumInsulatedTeapot_2.jpg','VacuumInsulatedTeapot_3.jpg','VacuumInsulatedTeapot_4.jpg','VacuumInsulatedTeapot_5.jpg','VacuumInsulatedTeapot_6.jpg','VacuumInsulatedTeapot_7.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Standard Lid in 1.2L / Intelligent Lid in 1.2L' }
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Material: #304 S/S Inner Wall ,Copper-platedlock Layer, #201 S/S Out Wall\nColor: White/Red/Green/Black/ Pink'  
            },
        ]
    },
//Cookware 6/Cookware-21
    {
        bySubcategory:21,//所属产品子类
        id:88,//产品ID
        name:'Lomon Professional Nonstick Fry Pan with Lid',//产品名
        img:['LomonProfessionalNonstickFryPanwithLid_1.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '316' },
                { id: 2, name: '304'}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Diameter: 32cm / 34cm / 36cm'  
            },
        ]
    },
    {
        bySubcategory:21,//所属产品子类
        id:119,//产品ID
        name:'Lomon Upgraded Nonstick Fry Pan with Lid and Handle',//产品名
        img:['LomonUpgradedNonstickFryPanwithLidandHandle_1.jpg','LomonUpgradedNonstickFryPanwithLidandHandle_2.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '316' },
                { id: 2, name: '304'}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Diameter: 32cm / 34cm / 36cm'  
            },
        ]
    },
    {
        bySubcategory:21,//所属产品子类
        id:120,//产品ID
        name:'Lomon Hybrid Cookware Frying Pan',//产品名
        img:['LomonHybridCookwareFryingPan_1.jpg','LomonHybridCookwareFryingPan_2.jpg','LomonHybridCookwareFryingPan_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '316' },
                { id: 2, name: '304'}
            ]
        }
        ],
        describe:[
            {
                code: 1,
                contact: 'Diameter: 26cm/28cm/30cm/32cm'
            }
        ]
    },

//School Pack 7/Japanese Style Lunch Box-22
    {
        bySubcategory:22,//所属产品子类
        id:89,//产品ID
        name:'Japanese Style Lunch Box',//产品名
        img:['JapaneseStyleLunchBox_1.jpg','JapaneseStyleLunchBox_2.jpg','JapaneseStyleLunchBox_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '1-Compartment' },
                { id: 2, name: '2-Compartment' },
                { id: 3, name: '3-Compartment' },
                { id: 4, name: '4-Compartment' },
                { id: 5, name: '6-Compartment' },
                { id: 6, name: '8-Compartment' },

            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Dimensions(MM): 135*105*55\nThickness(MM): 0.8\nMaterial: 201, 316, 304\nOuter Box Specification(CM): 43*34*30\nPacking Quantity: 60 pieces'  
            },
            {  
                code: 2,  
                contact: 'Dimensions(MM): 220*145*60\nThickness(MM): 0.8\nMaterial: 201, 316, 304\nOuter Box Specification(CM): 67*38*49\nPacking Quantity: 40 pieces'  
            },
            {  
                code: 3,  
                contact: 'Dimensions(MM): 330*145*60\nThickness(MM): 0.8\nMaterial: 201, 316, 304\nOuter Box Specification(CM): 71*38*50\nPacking Quantity: 30 pieces'  
            },
            {  
                code: 4,  
                contact: 'Dimensions(MM): 280*220*60\nThickness(MM): 0.8\nMaterial: 201, 316, 304\nOuter Box Specification(CM): 62*49*39\nPacking Quantity: 20 pieces'  
            },
            {  
                code: 5,  
                contact: 'Dimensions(MM): 330*285*60\nThickness(MM): 0.8\nMaterial: 201, 316, 304\nOuter Box Specification(CM): 62*36*45\nPacking Quantity: 12 pieces'  
            },
            {  
                code: 6,  
                contact: 'Dimensions(MM): 435*285*60\nThickness(MM): 0.8\nMaterial: 201, 316, 304\nOuter Box Specification(CM): 63*47*39\nPacking Quantity: 10 pieces'  
            },
        ]
    },
//School Pack 7/Stainless Steel Lunch Box-23
    {
        bySubcategory:23,//所属产品子类
        id:27,//产品ID
        name:'Lunch Box',//产品名
        img:['LunchBox_1.jpg','LunchBox_2.jpg','LunchBox_3.jpg'],//产品图片名，不需要写路径，
        note:'Suitable for OEM and E-Commerce Platform',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: '1-Layer' },  
                { id: 2, name: 'Double Layer' },  
                { id: 3, name: 'Triple Layer' },  
            ]
        },
        {
            id:2,
            name:'Color',
            items:[
                {
                    id: 1, name:'Blue' , isShowImg:true
                },
                {
                    id: 2, name:'Gray' , isShowImg:true
                },
                {
                    id: 3, name:'Red' , isShowImg:true
                }
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: "Gift Box Specifications: 24.3*16.8*8.3\nPCS:48\nBox:1\nOuter Box Specification (CM):69*50*52.5"  
            } ,
            {  
                code: 2,  
                contact: "Gift Box Specifications: 24.3*16.8*14.4\nPCS:32\nBox:1\nOuter Box Specification (CM):69.5*59*50.5"  
            } ,
            {  
                code: 3,  
                contact: "Gift Box Specifications: 24.3*20.2*16.8\nPCS:24\nBox:1\nOuter Box Specification (CM):69.5*50*62.5"  
            } 
        ]
    },      
    {   
        bySubcategory:23,//所属产品子类
        id:90,//产品ID
        name:'Lightweight Portable Insulated Lunch Box',//产品名
        img:['LightweightPortableInsulatedLunchBox_1.jpg','LightweightPortableInsulatedLunchBox_2.jpg','LightweightPortableInsulatedLunchBox_3.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Large' },
                { id: 2, name: 'Medium' },
                { id: 3, name: 'Small'}
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Dimensions(CM):7*27*21 \nMaterial: 304 Stainless Steel + PP\nColor: Blue/Yellow/Pink\nStyle: 2-Compartment / 3-Compartment / 4-Compartment/ 5-Compartment'  
            },
            {  
                code: 2,  
                contact: 'Dimensions(CM):6.5*24.5*17.5 \nMaterial: 304 Stainless Steel + PP\nColor: Blue/Yellow/Pink\nStyle: 22-Compartment / 3-Compartment / 4-Compartment/ 5-Compartment'  
            },
            {  
                code: 3,  
                contact: 'Dimensions(CM):6*20*15 \nMaterial: 304 Stainless Steel + PP\nColor: Blue/Yellow/Pink\nStyle: 2-Compartment / 3-Compartment / 4-Compartment/ 5-Compartment'
            },
        ]
    },
    {
        bySubcategory:23,//所属产品子类
        id:91,//产品ID
        name:'Multi Grid Lunchbox',//产品名
        img:['MultiGridLunchbox_1.jpg','MultiGridLunchbox_2.jpg','MultiGridLunchbox_3.jpg','MultiGridLunchbox_4.jpg','MultiGridLunchbox_5.jpg','MultiGridLunchbox_6.jpg','MultiGridLunchbox_7.jpg','MultiGridLunchbox_8.jpg'],//产品图片名，不需要写路径，
        note:'',//产品统一描述
        property:[
        {
            id:1,//产品属性ID，每个不同产品，这个都从1开始,
            name:'Specifications',
            items: [  
                { id: 1, name: 'Type A' , isShowImg:true},
                { id: 2, name: 'Tpey B' , isShowImg:true},
                { id: 3, name: 'Type C' , isShowImg:true},
                { id: 4, name: 'Tpey D' , isShowImg:true},
                { id: 5, name: 'Type E' , isShowImg:true},
                { id: 6, name: 'Tpey F' , isShowImg:true},
            ]
        }
        ],
        describe:[
            {  
                code: 1,  
                contact: 'Dimensions(CM): 22*15.3*4'  
            },
            {  
                code: 2,  
                contact: 'Dimensions(CM): 22*15.3*4'  
            },
            {  
                code: 3,  
                contact: 'Dimensions(CM): 26*18.6*4'  
            },
            {  
                code: 4,  
                contact: 'Dimensions(CM): 26*18.6*4'  
            },
            {  
                code: 5,  
                contact: 'Dimensions(CM): 28*22*4'  
            },
            {  
                code: 6,  
                contact: 'Dimensions(CM): 28*22*4'  
            },
        ]
    },

]
