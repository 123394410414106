<template>
    <div :id="!_isMobile() ? 'home' : 'home-app'">
        <!-- 配置slider组件 -->
        <slider ref="slider" :options="options">
            <slideritem v-for="(item,index) in pages" :key="index">                
                <van-image class="banner" :src="require('@/assets/' + item)" fit="cover"/>
            </slideritem>
            <!-- 设置loading,可自定义 -->
            <div slot="loading">loading...</div>
        </slider>
        <div class="home-product">
            <div v-for="c in productCategory" :key="c.id">                
                <van-divider>{{ c.name }}</van-divider>
                <transition name="fade" mode="out-in" appear>
                <ul class="clearfix">
                    <li class="left home-product-item" v-for="p in productShowList2[c.id]" :key="p.id" @click="goProudct(p.id)">                     
                        <van-image class="home-product-img" round :src="showimg(p.img[0]) ? require('@/assets/product/' + p.img[0]) : require('@/assets/product/no-img.jpg')" fit="cover"/>
                        <h3>{{ p.name }}</h3>
                    </li>                    
                    <li class="left home-product-more"><h4 v-show="isShowMoreBtn[c.id]" @click="clickMore(c.id)">See More</h4></li>
                </ul>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'
import { Image as VanImage, Divider } from 'vant'
import { productCategory } from '@/assets/js/product-category'
import { productList } from '@/assets/js/product'
export default {
    name: 'HomeView',
    data() {
        return {
            productCategory: productCategory,
            productList: productList,
            productShowList: [],
            productShowList2: [],            
            pages:['1.jpg','2.jpg','3.jpg','4.jpg','5.jpg','6.jpg','7.jpg','8.jpg','9.jpg','10.jpg','11.jpg'],            
            options: {
                pagination: true,
                thresholdDistance: 100, // Sliding distance threshold
                thresholdTime: 500, // Sliding time threshold decision                
                speed: 500, // Sliding speed
                // timingFunction: 'ease', // Sliding mode
                loop: true, // Infinite loop
                loopedSlides: 1,
                slidesToScroll: 1,
                autoplay: 5000 // Auto play[ms]
            }
        }
    },
    mounted() {
        let categroyProductCountArr = []
        for(let c of this.productCategory) {
            categroyProductCountArr[c.id] = 0                        
            for(let s of c.subCategory) {         
                for(let p of this.productList) {                    
                    if(p.bySubcategory == s.id) {
                        categroyProductCountArr[c.id]++
                    }
                }                
            }                            
        }        
        
        for(let c of this.productCategory) {
            //this.productShowList[c.id] = []
            this.$set(this.productShowList, c.id, new Array())
            this.$set(this.productShowList2, c.id, new Array())            
            while(this.productShowList[c.id].length < categroyProductCountArr[c.id]) {
                for(let s of c.subCategory) {
                    if(this.productShowList[c.id].length == categroyProductCountArr[c.id]) break
                    for(let p of this.productList) {
                        if(this.productShowList[c.id].length == categroyProductCountArr[c.id]) break
                        if(p.bySubcategory == s.id && this.productShowList[c.id].find(i => i.id == p.id) == undefined) {
                            this.productShowList[c.id].push(p)
                            break
                        }
                    }                
                }                
            }
        }

        //把12个先显示， productShowList2 才是现在显示的，productShowList 是一共的
        for(let c of this.productCategory) {
            this.$set(this.productShowList2, c.id, this.productShowList[c.id].filter((item, index) => {return index <= 11}))
        }
        
        //console.log('mounted', categroyProductCountArr, this.productShowList)
    },
    computed: {
        isShowMoreBtn() {
            let isShowMore = []
            for(let c of this.productCategory) {
                if(!this.productShowList[c.id]) {
                    isShowMore[c.id] = true
                    continue
                }
                if(this.productShowList[c.id].length == this.productShowList2[c.id].length) {
                    isShowMore[c.id] = false
                }else {
                    isShowMore[c.id] = true
                }
            }
            return isShowMore
        }
    },
    methods: {
        clickMore(cid) {            
            this.$set(this.productShowList2, cid, this.productShowList[cid].filter((item, index) => {return index <= (this.productShowList2[cid].length + 11)}))            
        },
        goProudct(id) {
            this.$router.push({ name: 'product', params: { id: id }})
        },
        // 判断本地是否有该文件
        showimg (url) {
            // 判断结果
            let result = false
            url = './' + url
            // 获取该目录下所有文件名
            const files = require.context('@/assets/product/', false, /\.jpg$/)
            const filedata = files.keys()
            // 判断是否有该文件
            filedata.forEach((item) => {
                if (item === url) {
                    result = true
                }
            })
            // 返回结果
            //console.log('showimg', url, result)
            return result
        },
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        }
    },
    components: {
        slider,
        slideritem,
        [VanImage.name]: VanImage,
        [Divider.name]: Divider,
    }
}
</script>
<style scoped>
#home {
    width: 1200px;
    margin: 0 auto; 
}

#home-app {
    width: 100%;
    margin: 0 auto;
}
#home-app .banner {
    height: 20rem;
}

.home-product {
    /*background-color: #e4e1d3;*/
    background-color: white;
    padding-bottom: 50px;
}

#home .home-product .van-divider {
    margin: 0;
    padding: 50px;
    padding-left: 500px;
    padding-right: 500px;
    font-size: 20px;
    color: #333;
    border-color: #333;
}
#home .home-product ul {
    width: 1150px;
    margin: 0 auto;
}
#home .home-product ul .home-product-item {
    text-align: center;
    width: 180px;
    height: 220px;
    margin-top: 10px;
    margin-left: 10px;
    cursor: pointer;
}
#home .home-product ul .home-product-item .home-product-img {
    width: 150px;
    height: 150px;
}

#home .home-product ul .home-product-more {
    text-align: center;
    width: 100%;
    height: 20px;
    margin-top: 10px;
}
#home .home-product ul .home-product-more h4{
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
}
#home .home-product h3 {    
    line-height: 25px;
    height: 50px;
    overflow:hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}


#home-app .home-product .van-divider {
    margin: 0;
    padding: 2rem;
    padding-left: 4rem;
    padding-right: 4rem;
    font-size: 1rem;
    color: #333;
    border-color: #333;
}
#home-app .home-product ul .home-product-item {
    text-align: center;
    width: 7rem;
    height: 8rem;
    margin-top: 10px;
    margin-left: 14px;
    cursor: pointer;
}
#home-app .home-product ul .home-product-item .home-product-img {
    width: 5rem;
    height: 5rem;
}
#home-app .home-product ul .home-product-more {
    text-align: center;
    width: 100%;
    height: 2rem;
    margin-top: 1rem;
}
#home-app .home-product ul .home-product-more h4{
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
}
#home-app .home-product h3 {    
    line-height: 1rem;
    height: 2rem;
    overflow:hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
</style>